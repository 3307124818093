import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import api from '../../../store/api';
import { useSelector } from 'react-redux';
import FeedbackModal from '../../Utilities/FeedbackModal';


export default function BulkUpload({}) {

    const [file, setFile] = useState(null);
    const [isValidFile, setIsValidFile] = useState(false);
    const [fileName, setFileName] = useState('');
    const [fileSize, setFileSize] = useState('');
    const [loading, setLoading] = useState(false)
    const [res, setRes] = useState({
        message: "",
        severity: "success",
        show: false
    })
    const [userType, setUserType] = useState('staff')

    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/vnd.ms-excel') {
            setIsValidFile(true);
            setFile(file);
            setFileName(file.name);
            setFileSize(file.size);
        } else {
            setIsValidFile(false);
            setRes({
                message: 'Please upload a valid Excel file.',
                severity: "error",
                show: true
            })
        }
        }
    }, []);

    const handleChange = (evt) => {
        setUserType(evt.target.value)
    }

    const token = useSelector((state) => state.loginSlice.token);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        multiple: false,
        accept: ['.xlsx', '.xls'],
    });

    const handleFileUpload = (evt) => {
        evt.preventDefault()
        if (isValidFile && file) {
        setLoading(true)
        const data = new FormData();
        data.append('file', file);
        data.append('userType', userType);
        api.post(`/api/users/bulk-invite-user/`, data,
            {headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Token ${token}`,
                },
            })
            .then((response) => {
                setRes({
                    message: response.data.message,
                    severity: 'success',
                    show: true
                })
                setFile(null);
                setIsValidFile(false);
                setFileName('');
                setFileSize('');
                setLoading(false)
            })
            .catch((error) => {
                setRes({
                    message: error.response.data.error,
                    severity: 'error',
                    show: true
                })
                if (error.response.data.status === 'error' && error.response.data.errorFileUrl) {
                    window.open(error.response.data.errorFileUrl, '_blank');
                }
                setLoading(false)
                if (error.response.data.accessories)
                    setAccessories(error.response.data.accessories)
            })
        }
    };

  return (
    <>
        <FeedbackModal message={res.message} severity = {res.severity} show = {res.show} onClose = {() => {
			setRes({
				message: "",
				severity: "success",
				show: false
			})
		}}/>
        {/* <div className="d-flex flex-column flex-start flex-column-fluid mb-10">
            <p className='fw-bold'>Example Screenshot:</p>
            <img src="/static/assets/media/bulk-upload-instructions/accessory.png" alt="" className="mw-100" />
        </div> */}
        <div className="d-flex flex-column flex-start flex-column-fluid mb-10">
            <p className='fw-bold'>Instructions:</p>
            <div className="d-flex flex-column">
                <li className="d-flex align-items-center py-2">
                    <span className="bullet me-3"></span> <span>You can only update one type of user at a time. All fields are required.</span>
                </li>
            </div>
            <div className="w-100">
					{/*begin::Heading*/}
					<div className="pb-2 pb-lg-2">
					</div>
					<div className="fv-row">
						{/*begin::Row*/}
						<div className="row">
							{/*begin::Col*/}
							<div className="col-lg-4">
								{/*begin::Option*/}
								<input type="radio" className="btn-check" name="userType" value="staff" id="kt_create_account_form_account_type_personal" checked={userType === 'staff'} onChange={handleChange}/>
								<label className="btn btn-outline btn-outline-dashed btn-outline-default p-3 d-flex align-items-center f-dc mb-10" htmlFor="kt_create_account_form_account_type_personal">
									{/*begin::Svg Icon | path: icons/duotune/communication/com005.svg*/}
									<span className="svg-icon svg-icon-3x ">
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
											<path d="M20 14H18V10H20C20.6 10 21 10.4 21 11V13C21 13.6 20.6 14 20 14ZM21 19V17C21 16.4 20.6 16 20 16H18V20H20C20.6 20 21 19.6 21 19ZM21 7V5C21 4.4 20.6 4 20 4H18V8H20C20.6 8 21 7.6 21 7Z" fill="black" />
											<path opacity="0.3" d="M17 22H3C2.4 22 2 21.6 2 21V3C2 2.4 2.4 2 3 2H17C17.6 2 18 2.4 18 3V21C18 21.6 17.6 22 17 22ZM10 7C8.9 7 8 7.9 8 9C8 10.1 8.9 11 10 11C11.1 11 12 10.1 12 9C12 7.9 11.1 7 10 7ZM13.3 16C14 16 14.5 15.3 14.3 14.7C13.7 13.2 12 12 10.1 12C8.10001 12 6.49999 13.1 5.89999 14.7C5.59999 15.3 6.19999 16 7.39999 16H13.3Z" fill="black" />
										</svg>
									</span>
									{/*end::Svg Icon*/}
									{/*begin::Info*/}
									<span className="d-block fw-bold text-start">
										<span className="text-dark fwt-bolder d-block fs-6 mb-2">Staff Member</span>
									</span>
									{/*end::Info*/}
								</label>
								{/*end::Option*/}
							</div>
							{/*end::Col*/}
							{/*begin::Col*/}
							<div className="col-lg-4">
								{/*begin::Option*/}
								<input type="radio" className="btn-check" name="userType" value="crew" id="kt_create_account_form_account_type_corporate"  checked={userType === 'crew'} onChange={handleChange} />
								<label className="btn btn-outline btn-outline-dashed btn-outline-default p-3 d-flex align-items-center f-dc" htmlFor="kt_create_account_form_account_type_corporate">
									{/*begin::Svg Icon | path: icons/duotune/finance/fin006.svg*/}
									<span className="svg-icon svg-icon-3x ">
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
											<path opacity="0.3" d="M20 15H4C2.9 15 2 14.1 2 13V7C2 6.4 2.4 6 3 6H21C21.6 6 22 6.4 22 7V13C22 14.1 21.1 15 20 15ZM13 12H11C10.5 12 10 12.4 10 13V16C10 16.5 10.4 17 11 17H13C13.6 17 14 16.6 14 16V13C14 12.4 13.6 12 13 12Z" fill="black" />
											<path d="M14 6V5H10V6H8V5C8 3.9 8.9 3 10 3H14C15.1 3 16 3.9 16 5V6H14ZM20 15H14V16C14 16.6 13.5 17 13 17H11C10.5 17 10 16.6 10 16V15H4C3.6 15 3.3 14.9 3 14.7V18C3 19.1 3.9 20 5 20H19C20.1 20 21 19.1 21 18V14.7C20.7 14.9 20.4 15 20 15Z" fill="black" />
										</svg>
									</span>
									{/*end::Svg Icon*/}
									{/*begin::Info*/}
									<span className="d-block fw-bold text-start">
										<span className="text-dark fwt-bolder d-block fs-6 mb-2">Crew Member</span>
									</span>
									{/*end::Info*/}
								</label>
								{/*end::Option*/}
							</div>
							{/*end::Col*/}
							{/*begin::Col*/}
							<div className="col-lg-4">
								{/*begin::Option*/}
								<input type="radio" className="btn-check" name="userType" value="client" id="kt_create_account_form_account_type_corporate2"  checked={userType === 'client'} onChange={handleChange} />
								<label className="btn btn-outline btn-outline-dashed btn-outline-default p-3 d-flex align-items-center f-dc" htmlFor="kt_create_account_form_account_type_corporate2">
									{/*begin::Svg Icon | path: icons/duotune/finance/fin006.svg*/}
									<span className="svg-icon svg-icon-3x">
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
											<path opacity="0.3" d="M20 15H4C2.9 15 2 14.1 2 13V7C2 6.4 2.4 6 3 6H21C21.6 6 22 6.4 22 7V13C22 14.1 21.1 15 20 15ZM13 12H11C10.5 12 10 12.4 10 13V16C10 16.5 10.4 17 11 17H13C13.6 17 14 16.6 14 16V13C14 12.4 13.6 12 13 12Z" fill="black" />
											<path d="M14 6V5H10V6H8V5C8 3.9 8.9 3 10 3H14C15.1 3 16 3.9 16 5V6H14ZM20 15H14V16C14 16.6 13.5 17 13 17H11C10.5 17 10 16.6 10 16V15H4C3.6 15 3.3 14.9 3 14.7V18C3 19.1 3.9 20 5 20H19C20.1 20 21 19.1 21 18V14.7C20.7 14.9 20.4 15 20 15Z" fill="black" />
										</svg>
									</span>
									{/*end::Svg Icon*/}
									{/*begin::Info*/}
									<span className="d-block fw-bold text-start">
										<span className="text-dark fwt-bolder d-block fs-6 mb-2">Beysix's Client</span>
									</span>
								</label>
							</div>
						</div>
					</div>
				</div>
        </div>
        <form className="form" action="#" method="post">
        <div className="fv-row">
            {isValidFile ? (
                <>
                    <p className='fw-bold'>Uploaded Excel File:</p>
                    <div className='d-flex' style={{justifyContent: 'flex-start', alignItems: 'center'}}>
                        <div style={{width: '100px', height: '100px', marginRight: '10px'}}>
                            <img src="/static/assets/media/excel-logo/1.png" alt="" className="mw-100" />
                        </div>
                        <div>
                            <p className='mb-0 ftw-bold'>File Name: {fileName}</p>
                            <p className='mb-0 ftw-bold'>File Size: {fileSize} kb</p>
                        </div>
                    </div>
                    <div className='d-flex justify-content-end'>
                        <button type="submit" onClick={handleFileUpload} className="btn btn-beysix" disabled={!loading ? false : true}>
                            {!loading ? <span className="indicator-label">Submit</span>
                            : <span className="indicator-progress">Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>}
                        </button>
                    </div>
                </>
            ): (
                <div {...getRootProps({ className: 'dropzone dz-clickable' })}  id="kt_dropzonejs_example_1">
                <input {...getInputProps()} />
                <div className="dz-message needsclick">
                    <i className="bi bi-file-earmark-arrow-up text-primary-beysix fs-3x"></i>
                    <div className="ms-4">
                        <h3 className="fs-5 ftw-bolder text-gray-900 mb-1">Drop the excel file here or click to upload.</h3>
                        <span className="fs-7 fw-bold opacity-75">Upload 1 file</span>
                    </div>
                </div>
            </div>)}
        </div>
    </form>
    </>
  )
}
