import React, {useState, useEffect} from 'react'
import UserTableActionsSubmenu from '../Utilities/UserTableActionsSubmenu/UserTableActionsSubmenu';
import getRandomHexColor from '../Utilities/getRandomHexColor';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import parseTimestamp from '../Utilities/parseTimeStamp';

export default function UserBRow({user, token, index, user_stat, roles, clients, selected, handleSelectChange}) {

    const [User, setUser] = useState({})

    useEffect(() => {
        setUser(user)
    }, [user])
    const permissions = useSelector((state) => state.loginSlice.permissions);
    const renderProfilePhoto = (profile) => {
        if (profile && (profile.profile_photo_path !== null && profile.profile_photo_path !== '')) {
          return <img src={profile.profile_photo_path} alt={`${profile.first_name} ${profile.last_name}`} className="user-list-img-display" />;
        } else if (profile) {
          const { randomColor, darkerShade, brighterShade } = getRandomHexColor();
          return <div className="symbol-label fs-5" style={{ background: brighterShade, color: darkerShade }}>{profile.first_name[0]}{profile.last_name[0]}</div>;
        } else {
          return null;
        }
      };
    
      const renderUserRole = (User) => {
        if (User.is_staff) {
          return null
        } else if (User.is_crew) {
          return null
        } else {
          if (User.profile && User.profile.client)
            return User.profile.client.name;
          else
            return 'Not Assigned'
        }
      };
    
      const renderUserOccupation = (profile) => {
        return profile && profile.occupation ? profile.occupation : 'Not Added';
      };
    
      const renderUserBrandCount = (profile) => {
        if (profile.brand_count === 1)
          return `Represents ${profile.brand_count} Brand`
        else
          return `Represents ${profile.brand_count} Brands`
      };
    
      const renderUserPhone = (profile) => {
        return profile && profile.phone_number ? (`+(254) `).concat(profile.phone_number) : 'Not Added';
      };

  return (
    <tr className={index % 2 === 0 ? 'odd' : 'even'}>
        <td style={{display: 'none'}}>
        {/* <div className="form-check form-check-sm form-check-custom form-check-solid"> */}
            {/* <input className="form-check-input" type="checkbox" checked={selected} value={user.id} onChange={handleSelectChange}/> */}
        {/* </div> */}
        </td>
        <td className="d-flex align-items-center">
        <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
          {user.is_client && (permissions["view_client_profile"]) ? 
            <Link to={`/user-management/users/user-profile/${User.id}`}>
            <div className="symbol-label">
                {renderProfilePhoto(User.profile)}
            </div>
            </Link> : user.is_crew && (permissions["view_crew_profile"]) ?
            <Link to={`/user-management/users/user-profile/${User.id}`}>
            <div className="symbol-label">
                {renderProfilePhoto(User.profile)}
            </div>
            </Link> : user.is_staff && (permissions["view_staff_profile"]) ?
            <Link to={`/user-management/users/user-profile/${User.id}`}>
            <div className="symbol-label">
                {renderProfilePhoto(User.profile)}
            </div>
            </Link>  :
            <div to={`/user-management/users/user-profile/${User.id}`}>
            <div className="symbol-label">
                {renderProfilePhoto(User.profile)}
            </div>
            </div>
            }
        </div>
        <div className="d-flex flex-column">
        {user.is_client && (permissions["view_client_profile"]) ?
            <><Link to={`/user-management/users/user-profile/${User.id}`} className="text-gray-800 text-hover-primary mb-1">{`${User.profile ? User.profile.first_name : ''} ${User.profile ? User.profile.last_name : ''}`}</Link><span>{User.email}</span></>
            : user.is_crew && (permissions["view_crew_profile"]) ?
            <><Link to={`/user-management/users/user-profile/${User.id}`} className="text-gray-800 text-hover-primary mb-1">{`${User.profile ? User.profile.first_name : ''} ${User.profile ? User.profile.last_name : ''}`}</Link><span>{User.email}</span></>
            : user.is_staff && (permissions["view_staff_profile"]) ?
            <><Link to={`/user-management/users/user-profile/${User.id}`} className="text-gray-800 text-hover-primary mb-1">{`${User.profile ? User.profile.first_name : ''} ${User.profile ? User.profile.last_name : ''}`}</Link><span>{User.email}</span></>
            : <>
              <div  className="text-gray-800 mb-1">{`${User.profile ? User.profile.first_name : ''} ${User.profile ? User.profile.last_name : ''}`}</div>
              <span>{User.email}</span>
              </>}
        </div>
        </td>
        {User.is_client && User.profile.client ? (
            <td>
              <Link
                to={`/client-management/clients/detailed-client-organization/${User.profile.client.id}`}
                className="text-gray-800 text-hover-primary mb-1"
              >
                {renderUserRole(User)}
              </Link>
            </td>
          ) : User.is_client ? (
            <td>
              <a className="text-gray-800 mb-1">Not Added</a>
            </td>
          ) : User.is_crew ? (
            User.profile.crew_role !== null && User.profile.crew_role !== '' ?
            <td>
              <a className="text-gray-800 mb-1">{User.profile.crew_role}</a>
            </td> : <td><a className="text-gray-800 mb-1">Not Added</a></td>
          ) : null}

        {User.is_client ? <td>{renderUserBrandCount(User.profile)}</td> : User.is_staff ? <td>{renderUserOccupation(User.profile)}</td> : null}
        <td>
        {user_stat !== 'unapproved' ?
            User.is_active ?
            <div className="badge badge-success fwt-bolder">Active</div>
            : <div className="badge badge-danger fwt-bolder">Deactivated</div>
            : User.is_email_verified ?
            <div className="badge badge-success fwt-bolder">Verified</div>
            : <div className="badge badge-danger fwt-bolder">Unverified</div>
        }
        </td>
        <td>
        {User.is_approved ?
            <div className="badge badge-success fwt-bolder">Approved</div>
            : <div className="badge badge-danger fwt-bolder">Not Approved</div>
        }
        </td>
        <td>{renderUserPhone(User.profile)}</td>
        <td>{User.profile ?   parseTimestamp(User.profile.created_at) : '-'}</td>
        {(permissions["can_approve_user"] || 
          permissions["can_unapprove_user"] || 
          permissions["can_activate_user"] || 
          permissions["can_deactivate_user"]) &&
        <UserTableActionsSubmenu _clients={clients} roles={roles} token={token} user={User} handleSuccess={(user) => {
            setUser(user)
        }}/>
        }
    </tr>
  )
}
