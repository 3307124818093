import React from 'react'

export default function SystemActiveStatus() {
  return (
    <div style={{
        margin: '0px auto',
        textAlign: 'center',
        transform: 'translate(-50%, 50%)',
        minHeight: '30vh',
        bottom: '50%',
        position: 'absolute',
        left: '50%'
    }}>
        <p style={{fontSize: 'xxx-large', fontWeight: 700, lineHeight: '50px'}}>System Is Currently Disabled</p>
        <p style={{    fontSize: 'x-large', fontWeight: 500}}>Contact your administrator.</p>
    </div>
  )
}
