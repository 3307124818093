import React, {useState, useEffect} from 'react'
import { useSelector } from 'react-redux'
import api from '../../../store/api'
import { useParams } from 'react-router'
import parseTimeStamp from '../../Utilities/parseTimeStamp'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { useNavigate } from 'react-router'

export default function ActivationDispatchTab() {

    const [dispatchNotes, setDispatchNotes] = useState([])
    
    const {id} = useParams()

    const navigate = useNavigate(null)

    const handleReceival = () => {
        navigate(`/activation-management/detailed-activation/receival/${id}`)
    }

    const token = useSelector((state) => state.loginSlice.token);
    const [isOpen, setIsOpen] = useState(false);
    const [currentAssetIndex, setCurrentAssetIndex] = useState(null);
    const [lightboxImageIndex, setLightboxImageIndex] = useState(0);
    const [allItemsReceived, setAllItemsReceived] = useState(false)
    const [tools, setTools] = useState([])
    const [accessories, setAccessories] = useState([])

    const handleAllItemsReceived = (dispatches) => {
      let flag = true
      for (let i = 0; i < dispatches.length; i++) {
        const air = dispatches[i].all_items_received
        if (air === false) {
          flag = false
        }
      }
      setAllItemsReceived(flag)
    }

    const getDispatchNotes = () => {
        api.get(`/api/activations/get-dispatch-notes/${id}/`,
            {headers: {'Content-Type': 'application/json', Authorization: `Token ${token}`}})
            .then((response) => {
                if (response.status === 200) {
                    const data = response.data
                    if (data){
                        setDispatchNotes(data.data)
                        handleAllItemsReceived(data.data)
                        setAccessories(data?.accessories_data?.accessories)
                        setTools(data?.tools_data?.tools)
                    }
                }
            })
            .catch((error) => {
                throw new Error('Error getting data.')
            })
    }

    const [clause, setClause] = useState('dispatch')

    useEffect(() => {
        getDispatchNotes()
    }, [])
  return (
    <>
    <div style={{textAlign: 'center'}}>
        <h2>{dispatchNotes[0]?.dispatch_name}</h2>
    </div>
    {allItemsReceived === false && <div className='d-flex justify-content-end mb-4' style={{minHeight: '38px'}}>
        <button onClick={() => handleReceival()} type="button" className="btn btn-sm btn-beysix me-3">
            Receive Items
            <span className="svg-icon svg-icon-3 ms-2 me-0 svg-icon-white">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="black"></rect>
                    <path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="black"></path>
                </svg>
            </span>
        </button>
    </div>}
    {dispatchNotes.length > 0 &&
  dispatchNotes.map((dispatchNote, noteIndex) => (
    <div
      className="card paper-table hover-card"
      key={noteIndex}
      style={{ marginTop: "30px", padding: "50px 125px" }}
    >
      {dispatchNote.dispatch_checklist && (
        <table>
          <thead>
            <tr>
              <th className="min-w-125px">Asset</th>
              <th className="min-w-250px">Items</th>
              <th className="min-w-125px">Condition Out</th>
              <th className="min-w-125px">Condition In</th>
              <th className="min-w-125px">Dispatch Images</th>
              <th className="min-w-125px">Receipt Images</th>
            </tr>
          </thead>
          <tbody>
            {dispatchNote.dispatch_checklist &&
              dispatchNote.dispatch_checklist.map((asset, assetIndex) => (
                <React.Fragment key={assetIndex}>
                  {/* First Row: Asset Name and First Item */}
                  <tr>
                    <td
                      rowSpan={asset.item_serials.length}
                      style={{ verticalAlign: "top" }}
                    >
                      {asset.asset_name}
                    </td>
                    <td>{asset.item_serials[0]?.serial_name}</td>
                    <td>{asset.item_serials[0]?.serial_condition_out}</td>
                    <td>{asset.item_serials[0]?.serial_condition_in}</td>
                    <td rowSpan={asset.item_serials.length}>
                      {asset.media?.length > 0 && (
                        <a
                          className="menu-link px-3 menu-rounded menu-gray-800 menu-state-bg-light-primary ftw-bold"
                          style={{ color: "#fa890f" }}
                          onClick={() => {
                            // Unique lightbox state per note
                            setClause('dispatch')
                            setCurrentAssetIndex(assetIndex); // Track the current asset
                            setLightboxImageIndex(0); // Start with the first image
                            setIsOpen({ ...isOpen, [noteIndex]: true }); // Open the lightbox for this note
                          }}
                        >
                        View Images
                        </a>
                      )}
                    </td>
                    <td rowSpan={asset.item_serials.length}>
                      {asset.receipt_media?.length > 0 && (
                        <a
                          className="menu-link px-3 menu-rounded menu-gray-800 menu-state-bg-light-primary ftw-bold"
                          style={{ color: "#fa890f" }}
                          onClick={() => {
                            // Unique lightbox state per note
                            setClause('receipt')
                            setCurrentAssetIndex(assetIndex); // Track the current asset
                            setLightboxImageIndex(0); // Start with the first image
                            setIsOpen({ ...isOpen, [noteIndex]: true }); // Open the lightbox for this note
                          }}
                        >
                        View Images
                        </a>
                      )}
                    </td>
                  </tr>
                  {/* Additional Rows: Remaining Items */}
                  {asset.item_serials.slice(1).map((item, itemIndex) => (
                    <tr key={itemIndex}>
                      <td>{item.serial_name}</td>
                      <td>{item.serial_condition_out}</td>
                      <td>{item.serial_condition_in}</td>
                    </tr>
                  ))}
                </React.Fragment>
              ))}
          </tbody>
        </table>
      )}
      {/* Lightbox for this dispatch note */}
      {isOpen[noteIndex] && currentAssetIndex !== null && (
        clause === 'dispatch' ? 
        <Lightbox
          mainSrc={
            dispatchNote.dispatch_checklist[currentAssetIndex]?.media[
              lightboxImageIndex
            ]?.media_path
          }
          nextSrc={
            dispatchNote.dispatch_checklist[currentAssetIndex]?.media[
              (lightboxImageIndex + 1) %
                dispatchNote.dispatch_checklist[currentAssetIndex]?.media?.length
            ]?.media_path
          }
          prevSrc={
            dispatchNote.dispatch_checklist[currentAssetIndex]?.media[
              (lightboxImageIndex +
                dispatchNote.dispatch_checklist[currentAssetIndex]?.media
                  ?.length -
                1) %
                dispatchNote.dispatch_checklist[currentAssetIndex]?.media?.length
            ]?.media_path
          }
          onCloseRequest={() =>
            setIsOpen({ ...isOpen, [noteIndex]: false }) // Close lightbox for this note
          }
          onMovePrevRequest={() =>
            setLightboxImageIndex(
              (lightboxImageIndex +
                dispatchNote.dispatch_checklist[currentAssetIndex]?.media
                  ?.length -
                1) %
                dispatchNote.dispatch_checklist[currentAssetIndex]?.media?.length
            )
          }
          onMoveNextRequest={() =>
            setLightboxImageIndex(
              (lightboxImageIndex + 1) %
                dispatchNote.dispatch_checklist[currentAssetIndex]?.media?.length
            )
          }
          imageCaption={
            <div style={{ padding: "10px", color: "#fff" }}>
              {
                dispatchNote.dispatch_checklist[currentAssetIndex]?.media[
                  lightboxImageIndex
                ]?.comment
              }
            </div>
          }
        /> :
        <Lightbox
          mainSrc={
            dispatchNote.dispatch_checklist[currentAssetIndex]?.receipt_media[
              lightboxImageIndex
            ]?.media_path
          }
          nextSrc={
            dispatchNote.dispatch_checklist[currentAssetIndex]?.receipt_media[
              (lightboxImageIndex + 1) %
                dispatchNote.dispatch_checklist[currentAssetIndex]?.receipt_media?.length
            ]?.media_path
          }
          prevSrc={
            dispatchNote.dispatch_checklist[currentAssetIndex]?.receipt_media[
              (lightboxImageIndex +
                dispatchNote.dispatch_checklist[currentAssetIndex]?.receipt_media
                  ?.length -
                1) %
                dispatchNote.dispatch_checklist[currentAssetIndex]?.receipt_media?.length
            ]?.media_path
          }
          onCloseRequest={() =>
            setIsOpen({ ...isOpen, [noteIndex]: false }) // Close lightbox for this note
          }
          onMovePrevRequest={() =>
            setLightboxImageIndex(
              (lightboxImageIndex +
                dispatchNote.dispatch_checklist[currentAssetIndex]?.receipt_media
                  ?.length -
                1) %
                dispatchNote.dispatch_checklist[currentAssetIndex]?.receipt_media?.length
            )
          }
          onMoveNextRequest={() =>
            setLightboxImageIndex(
              (lightboxImageIndex + 1) %
                dispatchNote.dispatch_checklist[currentAssetIndex]?.receipt_media?.length
            )
          }
          imageCaption={
            <div style={{ padding: "10px", color: "#fff" }}>
              {
                dispatchNote.dispatch_checklist[currentAssetIndex]?.receipt_media?.[
                  lightboxImageIndex
                ]?.comment || ""
              }
            </div>
          }
        />
      )}
      <table style={{marginTop: '20px'}}>
                <thead>
                <tr>
                    <th></th>
                    <th>Name</th>
                    <th>Phone Number</th>
                    <th>Date</th>
                </tr>
                </thead>
                <tbody>
                <React.Fragment>
                    <tr>
                        <td style={{ verticalAlign: 'top' }}>
                        <b>Dispatched By</b>
                        </td>
                        <td>{dispatchNote.staff_details?.dispatched_by?.name}</td>
                        <td>{dispatchNote.staff_details?.dispatched_by?.phone}</td>
                        <td>{parseTimeStamp(dispatchNote.staff_details?.dispatched_by?.date)}</td>
                    </tr>
                    <tr>
                        <td style={{ verticalAlign: 'top' }}>
                        <b>Supervised By</b>
                        </td>
                        <td>{dispatchNote.staff_details?.supervisor?.name}</td>
                        <td>{dispatchNote.staff_details?.supervisor?.phone}</td>
                        <td>{parseTimeStamp(dispatchNote.staff_details?.supervisor?.date)}</td>
                    </tr>
                    {(typeof dispatchNote.staff_details?.tl_details === 'object' && dispatchNote.staff_details?.tl_details !== null && !Array.isArray(dispatchNote.staff_details?.tl_details)) ? <tr>
                        <td style={{ verticalAlign: 'top' }}>
                        <b>TL Details</b>
                        </td>
                        <td>{dispatchNote.staff_details?.tl_details?.name}</td>
                        <td>{dispatchNote.staff_details?.tl_details?.phone}</td>
                        <td>{parseTimeStamp(dispatchNote.staff_details?.tl_details?.date)}</td>
                    </tr> : (Array.isArray(dispatchNote.staff_details?.tl_details)) ? 
                        <React.Fragment>
                            <tr>
                                <td style={{ verticalAlign: 'top' }} rowSpan={dispatchNote.staff_details?.tl_details?.length}>
                                <b>TL Details</b>
                                </td>
                                <td>{dispatchNote.staff_details?.tl_details[0]?.name}</td>
                                <td>{dispatchNote.staff_details?.tl_details[0]?.phone}</td>
                                <td>{parseTimeStamp(dispatchNote.staff_details?.tl_details[0]?.date)}</td>
                            </tr>
                            {dispatchNote.staff_details?.tl_details?.slice(1).map((tl, index) => 
                            <tr key={index}>
                                <td>{tl.name}</td>
                                <td>{tl.phone}</td>
                                <td></td>
                            </tr>
                            )}
                        </React.Fragment>
                    : null}
                    <tr>
                        <td style={{ verticalAlign: 'top' }}>
                        <b>Received By</b>
                        </td>
                        <td>{dispatchNote.staff_details?.received_by?.name}</td>
                        <td>{dispatchNote.staff_details?.received_by?.phone}</td>
                        <td>{dispatchNote.staff_details?.received_by && parseTimeStamp(dispatchNote.staff_details?.received_by?.date)}</td>
                    </tr>
                    </React.Fragment>
                </tbody>
            </table>

            {dispatchNote.transportation && <table style={{marginTop: '20px'}}>
                <thead>
                <tr>
                    <th></th>
                    <th>Driver Name</th>
                    <th>Phone Number</th>
                    <th>Number Plate</th>
                </tr>
                </thead>
                <tbody>
                <React.Fragment>
                    {/* First Row: Asset Name and First Item */}
                    <tr>
                        <td rowSpan={dispatchNote.transportation.length} style={{ verticalAlign: 'top' }}>
                        <b>Transportation</b>
                        </td>
                        <td>{dispatchNote.transportation[0]?.driver_name}</td>
                        <td>{dispatchNote.transportation[0]?.phone_number}</td>
                        <td>{dispatchNote.transportation[0]?.vehicle_number_plate}</td>
                    </tr>
                    {/* Additional Rows: Remaining Items */}
                    {dispatchNote.transportation.slice(1).map((transport, index) => (
                        <tr key={index}>
                        <td>{transport.driver_name}</td>
                        <td>{transport.phone_number}</td>
                        <td>{transport.vehicle_number_plate}</td>
                        </tr>
                    ))}
                    </React.Fragment>
                </tbody>
            </table>}
            </div>
  ))}

{tools.length > 0 && (
  <div
    className="card paper-table hover-card"
    style={{ marginTop: "30px", padding: "50px 125px" }}
  >
    <table>
      <thead>
        <tr>
          <th className="min-w-125px">Tool</th>
          <th className="min-w-125px">Individual Tool</th>
          <th className="min-w-125px">Unique Code</th>
        </tr>
      </thead>
      <tbody>
        {tools.map((tool, toolIndex) => (
          <React.Fragment key={toolIndex}>
            {/* First Row: Tool Name and First Tool Serial */}
            <tr>
              <td
                rowSpan={tool?.tool_serials?.length}
                style={{ verticalAlign: "top" }}
              >
                <b>{tool.tool_name}</b>
              </td>
              <td>{tool?.tool_serials[0]?.name}</td>
              <td>{tool?.tool_serials[0]?.code}</td>
            </tr>
            {/* Additional Rows for Remaining Tool Serials */}
            {tool?.tool_serials.slice(1).map((tool_serial, index) => (
              <tr key={index}>
                <td>{tool_serial.name}</td>
                <td>{tool_serial.code}</td>
              </tr>
            ))}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  </div>
)}

        

    {accessories.length > 0 && <div
      className="card paper-table hover-card"
      style={{ marginTop: "30px", padding: "50px 125px" }}>
        <table>
          <thead>
            <tr>
              <th className="min-w-125px"></th>
              <th className="min-w-125px">Accessory</th>
              <th className="min-w-125px">Quantity</th>
            </tr>
          </thead>
          <tbody>
            <React.Fragment>
              {/* First Row: Asset Name and First Item */}
              <tr>
                <td
                  rowSpan={accessories.length}
                  style={{ verticalAlign: "top" }}
                >
                  <b>Dispatched Accessories</b>
                </td>
                <td>{accessories[0]?.accessory_name}</td>
                <td>{accessories[0]?.quantity}</td>
              </tr>
              {accessories.slice(1).map((accessory, index) => (
                    <tr key={index}>
                      <td>{accessory.accessory_name}</td>
                      <td>{accessory.quantity}</td>
                    </tr>
                ))}
              </React.Fragment>
          </tbody>
          </table>
      </div>}
        
    </>
  )
}
