import React, {useState, useEffect, useRef} from 'react'
import { Link, useNavigate } from 'react-router-dom';
import api from '../../../store/api';
import FeedbackModal from '../../Utilities/FeedbackModal';
import passwordStrengthValidator from '../../Utilities/passwordStrengthValidator';

export default function SignUp() {
const navigate = useNavigate()
const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    agreed: false,
    userType: 'staff'
});
 
const firstName = useRef(null)
const lastName = useRef(null)
const userEmail = useRef(null)
const password = useRef(null)
const confirmPassword = useRef(null)


const [errors, setErrors] = useState({});
const [loading, setLoading] = useState(false)
const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: '' });
    if (name == 'password') {
        const {strong, feedback} = passwordStrengthValidator(value)
        !strong && setErrors({ ...errors, [name]: feedback })
    }
    e.target.classList.remove('input-is-invalid')
};

const [res, setRes] = useState({
    message: "",
    severity: "success",
    show: false
})
const [showPassword, setShowPassword] = useState(false);

const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
};

const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateFormData(formData);
    if (Object.keys(validationErrors).length === 0) {
        setLoading(true)
        try {
            const response = await api.post('/api/users/sign-up/', formData);
            setLoading(false);
            return navigate(response.data.url);
        } catch (error) {
            const message = error.response.data.message
            const severity = "error"
            const show = true
            setRes({
                message: message,
                severity: severity,
                show: show
            })
            setLoading(false);
        }
    } else {
        setErrors(validationErrors);
        setLoading(false)
    }
};


const validateFormData = (data) => {
    const errors = {};
    if (!data.firstName.trim()) {
      errors.firstName = 'First name is required';
      firstName.current.classList.add('input-is-invalid')
    }
    if (!data.lastName.trim()) {
      errors.lastName = 'Last name is required';
      lastName.current.classList.add('input-is-invalid')
    }
    if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = 'Invalid email address';
      userEmail.current.classList.add('input-is-invalid')
    }
    const {strong, feedback} = passwordStrengthValidator(data.password)
    if (!strong) {
        errors.password = feedback
        password.current.classList.add('input-is-invalid')
    }
    if (data.password !== data.confirmPassword) {
      errors.confirmPassword = 'Passwords do not match';
      confirmPassword.current.classList.add('input-is-invalid')
    }
    if (!data.agreed) {
      errors.agreed = 'You must agree to Terms and Conditions';
    }
    return errors;
};

return (
    <div className="w-lg-600px bg-body rounded shadow-sm-sm p-10 p-lg-15 mx-auto">
        {/*begin::Form*/}
        <FeedbackModal message={res.message} severity = {res.severity} show = {res.show} onClose = {() => {
            setRes({
                message: "",
                severity: "success",
                show: false
            })
        }}/>
        <form className="form w-100" noValidate id="kt_sign_up_form" onSubmit={handleSubmit} >
            {/*begin::Heading*/}
            <div className="mb-10 text-center">
                {/*begin::Title*/}
                <h1 className="text-dark mb-3">Create an Account</h1>
                {/*end::Title*/}
                {/*begin::Link*/}
                <div className="text-gray-400 fwt-bold fs-5"> Already have an account?
                <Link style = {{marginLeft: "5px"}} to="/auth/login" className="link-primary fwt-bolder">Sign in here</Link></div>
                {/*end::Link*/}
            </div>
            {/*end::Heading*/}
            {/*begin::Action*/}
            {/* <button type="button" className="btn btn-light-primary fwt-bolder w-100 mb-10">
            <img alt="Logo" src="assets/media/svg/brand-logos/google-icon.svg" className="h-20px me-3" />Sign in with Google</button>
            <div className="d-flex align-items-center mb-10">
                <div className="border-bottom border-gray-300 mw-50 w-100"></div>
                <span className="fwt-bold text-gray-400 fs-7 mx-2">OR</span>
                <div className="border-bottom border-gray-300 mw-50 w-100"></div>
            </div> */}
            {/*end::Separator*/}
            {/*begin::Input group*/}
            <div className="row fv-row mb-7">
                {/*begin::Col*/}
                <div className="col-xl-6">
                    <label className="form-label fwt-bolder text-dark fs-6">First Name</label>
                    <input className="form-control form-control-lg form-control-solid" ref={firstName}  onChange={handleChange} type="text" placeholder="" name="firstName" autoComplete="off" />
                    {errors.firstName && <div className="text-danger">{errors.firstName}</div>}
                </div>
                {/*end::Col*/}
                {/*begin::Col*/}
                <div className="col-xl-6">
                    <label className="form-label fwt-bolder text-dark fs-6">Last Name</label>
                    <input className="form-control form-control-lg form-control-solid" ref={lastName} onChange={handleChange}  type="text" placeholder="" name="lastName" autoComplete="off" />
                    {errors.lastName && <div className="text-danger">{errors.lastName}</div>}
                </div>
                {/*end::Col*/}
            </div>
            {/*end::Input group*/}
            {/*begin::Input group*/}
            <div className="fv-row mb-7">
                <label className="form-label fwt-bolder text-dark fs-6">Email</label>
                <input className="form-control form-control-lg form-control-solid" ref={userEmail} onChange={handleChange}  type="email" placeholder="" name="email" autoComplete="off" />
                {errors.email && <div className="text-danger">{errors.email}</div>}
            </div>
            {/*end::Input group*/}
            {/*begin::Input group*/}
            <div className="mb-10 fv-row" data-kt-password-meter="true">
                {/*begin::Wrapper*/}
                <div className="mb-1">
                    {/*begin::Label*/}
                    <label className="form-label fwt-bolder text-dark fs-6">Password</label>
                    {/*end::Label*/}
                    {/*begin::Input wrapper*/}
                    <div className="position-relative mb-3">
                        <input className="form-control form-control-lg form-control-solid" ref={password} onChange={handleChange}  type={showPassword ? 'text' : 'password'} placeholder="" name="password" autoComplete="off" />
                        {formData.password.length > 0 ? <span onClick={togglePasswordVisibility} className="btn btn-lg btn-icon position-absolute translate-middle top-50 end-0 me-n2" data-kt-password-meter-control="visibility">
                            {!showPassword ? <i className="bi bi-eye-slash fs-4"></i> : <i className="bi bi-eye fs-4"></i>}
                        </span> : null}
                    </div>
                    {/*end::Input wrapper*/}
                    {/*begin::Meter*/}
                    <div className="d-flex align-items-center mb-3" data-kt-password-meter-control="highlight">
                        <div className="flex-grow-1 bg-secondary2 bg-active-success rounded h-5px me-2"></div>
                        <div className="flex-grow-1 bg-secondary2 bg-active-success rounded h-5px me-2"></div>
                        <div className="flex-grow-1 bg-secondary2 bg-active-success rounded h-5px me-2"></div>
                        <div className="flex-grow-1 bg-secondary2 bg-active-success rounded h-5px"></div>
                    </div>
                    {/*end::Meter*/}
                </div>
                {/*end::Wrapper*/}
                {/*begin::Hint*/}
                {errors.password ? <div className="text-danger">{errors.password}</div> : <div className="text-muted">Use 8 or more characters with a mix of letters, numbers &amp; symbols.</div>}
                {/*end::Hint*/}
            </div>
            {/*end::Input group=*/}
            {/*begin::Input group*/}
            <div className="fv-row mb-5">
                <label className="form-label fwt-bolder text-dark fs-6">Confirm Password</label>
                <input className="form-control form-control-lg form-control-solid" ref={confirmPassword} onChange={handleChange}  type="password" placeholder="" name="confirmPassword" autoComplete="off" />
                {errors.confirmPassword && <div className="text-danger">{errors.confirmPassword}</div>}
            </div>
            {/*end::Input group*/}
            <div className="w-100">
                {/*begin::Heading*/}
                <div className="pb-10 pb-lg-15">
                    <div className="text-black fwt-bold fs-6">Who are you?</div>
                    {/*end::Notice*/}
                </div>
                {/*end::Heading*/}
                {/*begin::Input group*/}
                <div className="fv-row">
                    {/*begin::Row*/}
                    <div className="row">
                        {/*begin::Col*/}
                        <div className="col-lg-4">
                            {/*begin::Option*/}
                            <input type="radio" className="btn-check" name="userType" value="staff" id="kt_create_account_form_account_type_personal" checked={formData.userType === 'staff'} onChange={handleChange}/>
                            <label className="btn btn-outline btn-outline-dashed btn-outline-default p-3 d-flex align-items-center f-dc mb-10" htmlFor="kt_create_account_form_account_type_personal">
                                {/*begin::Svg Icon | path: icons/duotune/communication/com005.svg*/}
                                <span className="svg-icon svg-icon-3x ">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M20 14H18V10H20C20.6 10 21 10.4 21 11V13C21 13.6 20.6 14 20 14ZM21 19V17C21 16.4 20.6 16 20 16H18V20H20C20.6 20 21 19.6 21 19ZM21 7V5C21 4.4 20.6 4 20 4H18V8H20C20.6 8 21 7.6 21 7Z" fill="black" />
                                        <path opacity="0.3" d="M17 22H3C2.4 22 2 21.6 2 21V3C2 2.4 2.4 2 3 2H17C17.6 2 18 2.4 18 3V21C18 21.6 17.6 22 17 22ZM10 7C8.9 7 8 7.9 8 9C8 10.1 8.9 11 10 11C11.1 11 12 10.1 12 9C12 7.9 11.1 7 10 7ZM13.3 16C14 16 14.5 15.3 14.3 14.7C13.7 13.2 12 12 10.1 12C8.10001 12 6.49999 13.1 5.89999 14.7C5.59999 15.3 6.19999 16 7.39999 16H13.3Z" fill="black" />
                                    </svg>
                                </span>
                                {/*end::Svg Icon*/}
                                {/*begin::Info*/}
                                <span className="d-block fw-bold text-start">
                                    <span className="text-dark fwt-bolder d-block fs-6 mb-2">Staff Member</span>
                                </span>
                                {/*end::Info*/}
                            </label>
                            {/*end::Option*/}
                        </div>
                        {/*end::Col*/}
                        {/*begin::Col*/}
                        <div className="col-lg-4">
                            {/*begin::Option*/}
                            <input type="radio" className="btn-check" name="userType" value="crew" id="kt_create_account_form_account_type_corporate"  checked={formData.userType === 'crew'} onChange={handleChange} />
                            <label className="btn btn-outline btn-outline-dashed btn-outline-default p-3 d-flex align-items-center f-dc" htmlFor="kt_create_account_form_account_type_corporate">
                                {/*begin::Svg Icon | path: icons/duotune/finance/fin006.svg*/}
                                <span className="svg-icon svg-icon-3x ">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path opacity="0.3" d="M20 15H4C2.9 15 2 14.1 2 13V7C2 6.4 2.4 6 3 6H21C21.6 6 22 6.4 22 7V13C22 14.1 21.1 15 20 15ZM13 12H11C10.5 12 10 12.4 10 13V16C10 16.5 10.4 17 11 17H13C13.6 17 14 16.6 14 16V13C14 12.4 13.6 12 13 12Z" fill="black" />
                                        <path d="M14 6V5H10V6H8V5C8 3.9 8.9 3 10 3H14C15.1 3 16 3.9 16 5V6H14ZM20 15H14V16C14 16.6 13.5 17 13 17H11C10.5 17 10 16.6 10 16V15H4C3.6 15 3.3 14.9 3 14.7V18C3 19.1 3.9 20 5 20H19C20.1 20 21 19.1 21 18V14.7C20.7 14.9 20.4 15 20 15Z" fill="black" />
                                    </svg>
                                </span>
                                {/*end::Svg Icon*/}
                                {/*begin::Info*/}
                                <span className="d-block fw-bold text-start">
                                    <span className="text-dark fwt-bolder d-block fs-6 mb-2">Crew Member</span>
                                </span>
                                {/*end::Info*/}
                            </label>
                            {/*end::Option*/}
                        </div>
                        {/*end::Col*/}
                        {/*begin::Col*/}
                        <div className="col-lg-4">
                            {/*begin::Option*/}
                            <input type="radio" className="btn-check" name="userType" value="client" id="kt_create_account_form_account_type_corporate2"  checked={formData.userType === 'client'} onChange={handleChange} />
                            <label className="btn btn-outline btn-outline-dashed btn-outline-default p-3 d-flex align-items-center f-dc" htmlFor="kt_create_account_form_account_type_corporate2">
                                {/*begin::Svg Icon | path: icons/duotune/finance/fin006.svg*/}
                                <span className="svg-icon svg-icon-3x">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path opacity="0.3" d="M20 15H4C2.9 15 2 14.1 2 13V7C2 6.4 2.4 6 3 6H21C21.6 6 22 6.4 22 7V13C22 14.1 21.1 15 20 15ZM13 12H11C10.5 12 10 12.4 10 13V16C10 16.5 10.4 17 11 17H13C13.6 17 14 16.6 14 16V13C14 12.4 13.6 12 13 12Z" fill="black" />
                                        <path d="M14 6V5H10V6H8V5C8 3.9 8.9 3 10 3H14C15.1 3 16 3.9 16 5V6H14ZM20 15H14V16C14 16.6 13.5 17 13 17H11C10.5 17 10 16.6 10 16V15H4C3.6 15 3.3 14.9 3 14.7V18C3 19.1 3.9 20 5 20H19C20.1 20 21 19.1 21 18V14.7C20.7 14.9 20.4 15 20 15Z" fill="black" />
                                    </svg>
                                </span>
                                {/*end::Svg Icon*/}
                                {/*begin::Info*/}
                                <span className="d-block fw-bold text-start">
                                    <span className="text-dark fwt-bolder d-block fs-6 mb-2">Beysix's Client</span>
                                </span>
                                {/*end::Info*/}
                            </label>
                            {/*end::Option*/}
                        </div>
                        {/*end::Col*/}
                    </div>
                    {/*end::Row*/}
                </div>
                {/*end::Input group*/}
            </div>
            {/*begin::Input group*/}
            <div className="fv-row mb-10">
                <label className="form-check form-check-custom form-check-solid form-check-inline">
                    <input className="form-check-input" type="checkbox" name="agreed" value="1" onChange={handleChange}/>
                    <span className="form-check-label fwt-bold text-gray-700 fs-6">I Agree
                    <a  className="ms-1 link-primary">Terms and conditions</a>.</span>
                </label>
                {errors.agreed && <div className="text-danger">{errors.agreed}</div>}
            </div>
            {/*end::Input group*/}
            {/*begin::Actions*/}
            <div className="text-center">
                <button type="submit" id="kt_sign_up_submit" className="btn btn-lg btn-beysix">
                    {!loading ? <span className="indicator-label">Submit</span> :
                    <span className="indicator-progress" style={{display: "block"}}>Please wait...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>}
                </button>
            </div>
            {/*end::Actions*/}
        </form>
        {/*end::Form*/}
    </div>
  )
}
