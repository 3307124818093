import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar'; // Correct import
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { parse } from 'date-fns'; // We can continue using date-fns for date parsing
import moment from 'moment';
import events from "./events";
import { useSelector } from 'react-redux';
import api from '../../store/api';
import CalendarSkeleton from './CustomRibbon/CalendarSkeleton/CalendarSkeleton';

moment.locale("en-GB");
const localizer = momentLocalizer(moment);

export default function ActivationCalendar() {
  
  const [eventsData, setEventsData] = useState([]);
  const [pageLoading, setPageLoading] = useState(false)
  const [timeline, setTimeline] = useState({})

  const token = useSelector((state) => state.loginSlice.token);

  const handleSelect = ({ start, end }) => {
    console.log(start);
    console.log(end);
    const title = window.prompt("New Event name");
    if (title)
      setEventsData([
        ...eventsData,
        {
          start,
          end,
          title
        }
      ]);
  };

  const handleNavigate = (date, view) => {
    const monthNumber = date.getMonth() + 1;;
    const year = date.getFullYear();
    handleGetRequest(monthNumber, year)
  };

  const handleGetRequest = (month, year) => {
    setPageLoading(true)
    api.get(`/api/calendar/activation-calendar/${month}/${year}`,
      {headers: {
              'Content-Type': 'application/json',
              Authorization: `Token ${token}`,
          },
      })

      .then((response) => {
          if (response.status === 200) {
              const _events = []
              const data = response.data.activation_data
              if (data.length > 0){
                  for (let i = 0; i < data.length; i++) {
                    const event = convertActivationToEvents(data[i])
                    _events.push(event)
                  }
                  setEventsData(_events.flat())
              }
          }
      })
      
      
      .catch((error) => {
          console.log(error)
          if (error.status === 404) {

          } else {
              throw new Error(error)
          }
      })
      
    setPageLoading(false)
  }

  const getEventStyle = (event) => {
    let backgroundColor;
  
    if (event.title.includes("Loading")) {
      backgroundColor = "#cd00cd"; // Yellow for Loading
    } else if (event.title.includes("Setup")) {
      backgroundColor =  "#2196f3";// Green for Event
    } else if (event.title.includes("Event")) {
      backgroundColor = "#4caf50";// Blue for Setup
    } else if (event.title.includes("Set Down")) {
      backgroundColor = "#f44336"; // Red for Set Down
    }
  
    return {
      style: {
        backgroundColor,
        color: "white", // Ensure text is visible
        borderRadius: "5px", // Optional: Add rounded corners
        border: "none", // Optional: Remove borders
      },
    };
  };

  const getActivationDates = () => {
    const today = new Date();
    const month = today.getMonth() + 1;  
    const year = today.getFullYear();
    const updatedTimeline = { ...timeline, year, month };
    handleGetRequest(month, year)
    setTimeline(updatedTimeline);
  }

  const convertActivationToEvents = (activation) => {
    const events = [];
  
    const addEvent = (title, date) => {
      const eventDate = new Date(date);
      events.push({
        id: `${activation.id}-${title}`, 
        title: `${activation.activation_name} (${title})`,
        start: new Date(eventDate.getFullYear(), eventDate.getMonth(), eventDate.getDate()),
        end: new Date(eventDate.getFullYear(), eventDate.getMonth(), eventDate.getDate())
      });
    };
    
    addEvent("Loading", activation.loading_date);
    addEvent("Setup", activation.setup_date);
    addEvent("Event", activation.event_date);
    addEvent("Set Down", activation.set_down_date);
  
    return events;
  };

  useEffect(() => {
    getActivationDates()
  }, [])

  return (
    <div className="card" style={{padding: '20px', minHeight: "80vh"}}>
      {!pageLoading ? <Calendar
        views={["month", "day", "agenda"]}
        selectable
        localizer={localizer}
        defaultDate={new Date()}
        defaultView="month"
        onNavigate={handleNavigate}
        events={eventsData}
        style={{ height: "80vh" }}
        // onSelectEvent={(event) => alert(event.title)}
        // onSelectSlot={handleSelect}
        eventPropGetter={getEventStyle}
      /> : <CalendarSkeleton />}
    </div>
  );
}
