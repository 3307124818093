import React, {useState, useCallback, useRef, useEffect} from 'react'
import { useDropzone } from 'react-dropzone';
import MainModal from '../../Modals/MainModal';
import CreateItem from '../../Items/CreateItem/CreateItem';
import { useSelector } from 'react-redux';
import api from '../../../store/api';
import FeedbackModal from '../../Utilities/FeedbackModal';
import CreateTool from '../../Tools/CreateTool';
import ProjectAssetsMultipleSearchComponent from '../../Utilities/ProjectAssetsMultipleSearchComponent';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet';
import "leaflet/dist/leaflet.css";
import L from 'leaflet';
import 'leaflet-search/dist/leaflet-search.min.css';
import 'leaflet-search';
import CountySearchComponent from '../../Utilities/CountySearchComponent';
import RegionSearchComponent from '../../Utilities/RegionSearchComponent';
import AgencySearchComponent from '../../Utilities/AgencySearchComponent';

export default function CreateActivation() {

    // Leaflet Map Section
    const initCoordinates = [-1.2460227009959508, 36.68168221244762];
    const marker = useRef(null)
    const mapRef = useRef(null)
    const [markerPosition, setMarkerPosition] = useState(null);
   
    const onMapReady = (map) => {
        map = map.current
        if (map){
            map.invalidateSize()
        }
    };

    const handleMapClick = (e) => {
        setCoordinates(e.latlng);
    };

    const MapEventsHandler = ({ setMarkerPosition }) => {
        const map = useMapEvents({
          click: (e) => {
            setMarkerPosition(e.latlng);
            handleUpdateCoordinates(e.latlng.lat, e.latlng.lng)
          }
        });

        return null;
    };

    const customIcon = L.icon({
        iconUrl: '/static/assets/media/illustrations/beysix-1/location-pin.png',
        iconSize: [38, 38],
        iconAnchor: [19, 38],
        popupAnchor: [0, -38]
    });

    function handleUpdateCoordinates(lat, long) {
        setActivationData({...activationData, activationLocationLatitude: lat, activationLocationLongitude: long})
    }

    const handleSplitCoordinates = (input) => {
        const regex = /(-?\d+(\.\d+)?)\s*[,;|\s]\s*(-?\d+(\.\d+)?)/;
        const match = input.match(regex);
        if (match) {
            const latitude = parseFloat(match[1]);
            const longitude = parseFloat(match[3]);
            if (latitude >= -90 && latitude <= 90 && longitude >= -180 && longitude <= 180) {
            return [latitude, longitude];
            } else {
                return false
            }
        } else {
            return false
        }
    }

    const handleManualCoordinatesChange = (evt) => {
        const value = evt.target.value
        const coords = handleSplitCoordinates(value)
        if (coords !== false) {
            setActivationData({...activationData, activationLocationLatitude: coords[0], activationLocationLongitude: coords[1]})
            setMarkerPosition({lat: parseFloat(coords[0]), lng: parseFloat(coords[1])})
            const marker = L.marker([51.505, -0.09], { icon: customIcon }).addTo(mapRef.current);
            marker.setLatLng([coords[0], coords[1]]);
            const newLatLng = marker._latlng;
            mapRef.current.flyTo(newLatLng, 14);
            setErrors({...errors, activationCoordinatesError: ''}) 
        } else {
            setErrors({...errors, activationCoordinatesError: 'Paste coordinates into this input. It will not accept invalid coordinates.'}) 
        }
    }
    
    const [coordinates, setCoordinates] = useState(null);
    
    const [activationData, setActivationData] = useState({
        activationName: '',
        activationProjects: [],
        activationDescription: '',
        isBeysixActivatingAgency: true,
        activatingAgencies: [],
        activationLocationLongitude: "",
        activationLocationLatitude: "",
        countyId: 0,
        regionId: 0,
        loadingDate: "",
        setUpDate: "",
        setDownDate: "",
        eventDate: "",
        locationName: "",
        checklist: [],
        activationTaccs: [],
        activationTools: [],
        activationAccessories: [],
        handlingAgencies: [],
        activationCost: ''
    })

    const [selectedHandlingAgencies, setSelectedHandlingAgencies] = useState([])

    const [errors, setErrors] = useState({
        activationNameError: '',
        activationProjectsError: [],
        activationDescriptionError: '',
        isBeysixActivatingAgencyError: true,
        activatingAgencyError: 0,
        activationCoordinatesError: "",
        countyIdError: 0,
        regionIdError: 0,
        loadingDateError: "",
        setUpDateError: "",
        setDownDateError: "",
        locationNameError: "",
        checklistError: []
    })

    const [successfullSubmission, setSuccessfullSubmission] = useState(false)
    const [selectedItems, setSelectedItems] = useState([])
    const [currentTab, setCurrentTab] = useState(1)
    const maxTab = 2
    const [selectedTools, setSelectedTools] = useState([])
    const [toolModal, setToolModal] = useState(false)

    const [quantity, setQuantity] = useState(1)
    const [iteration, setIteration] = useState(1)
    
    const handleChange = (evt) => {
        const target = evt.target;
        const name = target.name;
        let value = target.value;
    
        if (name === 'assetQuantity') {
            if (parseInt(value) === 0) {
                value = 1; // Ensure assetQuantity is at least 1
            }
    
            setQuantity(value);
    
            // Use the new value directly for calculations
            if (parseInt(value) > 1) {
                const activationName = `${ogAssetName} ${iteration}`;
                setActivationData({ ...activationData, activationName: activationName, assetQuantity: value });
            } else {
                const activationName = `${ogAssetName}`;
                setActivationData({ ...activationData, activationName: activationName, assetQuantity: value });
            }
        } else {
            if (name === 'activationName') {
                setOgAssetName(value)
            }
            setActivationData({ ...activationData, [name]: value });
        }
    };

    const [ogAssetName, setOgAssetName] = useState('')
    const token = useSelector((state) => state.loginSlice.token);
    const [assetImages, setAssetImages] = useState([]);

    const handleValidateStep1 = () => {
        let isValid = true; // Start assuming the form is valid
        let newErrors = {}; // Collect all errors
    
        // Validate activationName
        if (activationData.activationName.length === 0) {
            newErrors.activationName = 'A name is required for your activation.';
            isValid = false;
        }
    
        // Validate assetProject
        if (activationData.assetProject === '' || parseInt(activationData.assetProject) === 0) {
            newErrors.assetProject = 'You must select a project for your activation';
            isValid = false;
        }
    
        // Update errors state
        setErrors(newErrors);
    
        return isValid; // Return the validity status
    }
    

    const handleContinue = () => {
        if (handleValidateStep1())
            setCurrentTab(currentTab + 1)
    }

    const onDrop = useCallback((acceptedFiles) => {
		
		const newImages = acceptedFiles
			.filter(file => !assetImages.some(img => img.name === file.name))
			.map(file => Object.assign(file, {
				preview: URL.createObjectURL(file)
			}));
			setAssetImages(prevImages => {
				const updatedImages = [...prevImages, ...newImages];
				return updatedImages;
			});
	
			setActivationData(prevData => {
				const updatedAssetData = {
					...prevData,
					assetImages: [...prevData.assetImages, ...acceptedFiles]
				};
				return updatedAssetData;
			});
	}, [assetImages, setAssetImages, setActivationData]);
	
	const [submitLoading, setSubmitLoading] = useState(false)
    const [itemModal, setItemModal] = useState(false)
	const { getRootProps, getInputProps } = useDropzone({
		accept: 'image/*',
		onDrop
	});


    const [res, setRes] = useState({
		message: '',
		severity: "success",
		show: false
	})

    const sendActivationRequest = (activationData) => {
        const data = JSON.stringify(activationData)
        if (data) {
			setSubmitLoading(true)
			api.post(`/api/activations/activation/`, data,
                {headers: {
						'Content-Type': 'application/json',
                        Authorization: `Token ${token}`,
                    },
                })
                .then((response) => {
                    if (response.status === 201) {
                        setRes({
							message: response.data.message,
							severity: "success",
							show: true
						})
                        
                        setActivationData({
                            activationName: '',
                            activationProjects: [],
                            activationDescription: '',
                            isBeysixActivatingAgency: true,
                            activatingAgency: 0,
                            activationLocationLongitude: "",
                            activationLocationLatitude: "",
                            countyId: 0,
                            regionId: 0,
                            loadingDate: "",
                            setUpDate: "",
                            setDownDate: "",
                            locationName: "",
                            checklist: [],
                            activationCost: ''
                        })

                        setCheckedItems([])
                        setSelectedProjects([])
                        setSelectedProjectsIds([])
                        setCoordinates(null)
                        setCurrentTab(1)
                        setMarkerPosition(null)
                        setSubmitLoading(false)
                        setSuccessfullSubmission(true)

                        setErrors({
                            activationNameError: '',
                            activationProjectsError: '',
                            activationDescriptionError: '',
                            isBeysixActivatingAgencyError: '',
                            activatingAgencyError: '',
                            activationLocationLongitudeError: "",
                            activationLocationLatitudeError: "",
                            countyIdError: '',
                            regionIdError: '',
                            loadingDateError: "",
                            setUpDateError: "",
                            setDownDateError: "",
                            locationNameError: "",
                            checklistError: ''
                        })
                        
                        setSuccessfullSubmission(false)
                    }
                })
                .catch((error) => {
                    setRes({
						message: error.response.data.error,
						severity: "error",
						show: true
					})
					setSubmitLoading(false)
                })
		}
    }

    const handleSubmit = (evt) => {
        evt.preventDefault()
        setActivationData({...activationData, checklist: checkedItems})
        let activationPayload = activationData;
        activationPayload['checklist'] = checkedItems
        sendActivationRequest(activationPayload)
    }

    const [selectedProjects, setSelectedProjects] = useState([])

    const [checkedItems, setCheckedItems] = useState({});

    // Function to check/uncheck items
    const handleItemCheck = (projectId, assetId, itemId) => {
        setCheckedItems(prev => {
        const updatedChecked = { ...prev };
        updatedChecked[projectId] = updatedChecked[projectId] || {};
        updatedChecked[projectId][assetId] = updatedChecked[projectId][assetId] || {};

        // Toggle the item checked state
        updatedChecked[projectId][assetId][itemId] = !updatedChecked[projectId][assetId][itemId];

        return updatedChecked;
        });
    };

    // Function to check/uncheck assets
    const handleAssetCheck = (projectId, assetId, projectIndex) => {
        setCheckedItems(prev => {
        const updatedChecked = { ...prev };
        updatedChecked[projectId] = updatedChecked[projectId] || {};
        updatedChecked[projectId][assetId] = updatedChecked[projectId][assetId] || {};

        const assetChecked = isAssetChecked(projectId, assetId, projectIndex);
        selectedProjects[projectIndex].assets_items.find(asset => asset.asset_id === assetId).items.forEach(item => {
            updatedChecked[projectId][assetId][item.item_id] = !assetChecked;
        });

        return updatedChecked;
        });
    };

    // Helper function to check if all items under an asset are checked
    const isAssetChecked = (projectId, assetId, projectIndex) => {
        const asset = selectedProjects[projectIndex].assets_items.find(asset => asset.asset_id === assetId);
        const itemsChecked = asset.items.every(item => checkedItems[projectId]?.[assetId]?.[item.item_id]);
        return itemsChecked;
    };

    // Function to check/uncheck projects
    const handleProjectCheck = (projectId, projectIndex) => {
        setCheckedItems(prev => {
        const updatedChecked = { ...prev };
        updatedChecked[projectId] = updatedChecked[projectId] || {};

        const projectChecked = isProjectChecked(projectId, projectIndex);
        selectedProjects[projectIndex].assets_items.forEach(asset => {
            updatedChecked[projectId][asset.asset_id] = updatedChecked[projectId][asset.asset_id] || {};
            asset.items.forEach(item => {
            updatedChecked[projectId][asset.asset_id][item.item_id] = !projectChecked;
            });
        });

        return updatedChecked;
        });
    };

    // Helper function to check if all assets in a project are checked
    const isProjectChecked = (projectId, projectIndex) => {
        const allAssetsChecked = selectedProjects[projectIndex].assets_items.every(asset => isAssetChecked(projectId, asset.asset_id, projectIndex));
        return allAssetsChecked;
    };

    const [selectedProjectsIds, setSelectedProjectsIds] = useState([])

    const handleRemoveProject = (index) => {
        setSelectedProjectsIds(selectedProjectsIds.filter((_, i) => i !== index));
        setSelectedProjects(selectedProjects.filter((_, i) => i !== index));
        
        const items = checkedItems
        let keys = Object.keys(items);
        let targetKey = keys[index];
        delete items[targetKey];
        setCheckedItems(items)
    };

    const removeProjectById = (id) => {
        const activationProjects = [...activationData.activationProjects]
        const updatedActivationProjects = activationProjects.filter(project => project.id !== id)
        setActivationData({...activationData, activationProjects: updatedActivationProjects})
    }

    const removeActivatingAgencyById = (id) => {
        const activatingAgencies = [...activationData.activatingAgencies]
        const updatedActivatingAgencies = activatingAgencies.filter(agency => agency !== id)
        setActivationData({...activationData, activatingAgencies: updatedActivatingAgencies})
        setSelectedAgencies(selectedAgencies.filter(agency => agency.id !== id))
    }

    const removeHandlingAgencyById = (id) => {
        const handlingAgencies = [...activationData.handlingAgencies]
        const updatedHandlingAgencies = handlingAgencies.filter(agency => agency !== id)
        setActivationData({...activationData, handlingAgencies: updatedHandlingAgencies})
        setSelectedHandlingAgencies(selectedHandlingAgencies.filter(agency =>
             agency.id !== id
        ))
    }

    const [agencies, setAgencies] = useState([])
    const getAgencies = () => {
        api.get('/api/agencies/agencies', {headers: {'Content-Type': 'application/json', Authorization: `Token ${token}`}})
        .then((response) => {
            const data = response.data.results
            setAgencies(data)
        })
        .catch((error) => {
            setRes({
                message: error.response.data.error,
                severity: "error",
                show: true
            })
        })
    }

    const [selectedAgencies, setSelectedAgencies] = useState([])
    
    useEffect(() => {
        getAgencies()
    }, [])

  return (
    <div className="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid" id="kt_create_account_stepper" data-kt-stepper="true">
    <MainModal 
        handleClose={() => setItemModal(false)} 
        show={itemModal}
        modalTitle='Create a new item'>
            <CreateItem madeModal={true} returnNewItem={(item) => {
                setSelectedItems([...selectedItems, item])
                const _itemsId = activationData.assetItems
                _itemsId.push(item.id)
                setActivationData({...activationData, assetItems: _itemsId})
                setItemModal(false)
            }} />
    </MainModal>
    <MainModal 
        handleClose={() => setToolModal(false)} 
        show={toolModal}
        modalTitle='Create a new tool'>
        <CreateTool returnResults={(tool, message) => {
                if (tool) {
                    if (!activationData.assetTools.includes(tool.id)) {
                        const _tools = activationData.assetTools
                        _tools.push(tool.id)
                        setActivationData({...activationData, assetTools: _tools})
                        setSelectedTools([...selectedTools, tool])
                    }
                }
                setToolModal(false)
                setRes({
                    message: message,
                    severity: "success",
                    show: true
                })
            }
        } />
    </MainModal>
    <FeedbackModal message={res.message} severity = {res.severity} show = {res.show} onClose = {() => {
			setRes({
				message: "",
				severity: "success",
				show: false
			})
    }}/>
    <div className="d-flex justify-content-center bg-body rounded justify-content-xl-start flex-row-auto w-xl-300px w-xxl-400px me-9">
    {/*begin::Wrapper*/}
    <div className="px-6 px-lg-10 px-xxl-15 py-20">
        {/*begin::Nav*/}
        <div className="stepper-nav">
            {/*begin::Step 1*/}
            <div className={currentTab > 1 ? "stepper-item completed" : "stepper-item current"} data-kt-stepper-element="nav">
                {/*begin::Line*/}
                <div className="stepper-line w-40px"></div>
                {/*end::Line*/}
                {/*begin::Icon*/}
                <div className="stepper-icon w-40px h-40px">
                    <i className="stepper-check fas fa-check"></i>
                    <span className="stepper-number">1</span>
                </div>
                {/*end::Icon*/}
                {/*begin::Label*/}
                <div className="stepper-label">
                    <h4 className="stepper-title">Activation Details</h4>
                    <div className="stepper-desc ftw-bold">Add basic details for your activation.</div>
                </div>
                {/*end::Label*/}
            </div>
            {/*end::Step 1*/}
            {/*begin::Step 2*/}
            <div className={currentTab > 2 ? "stepper-item completed" : currentTab === 2 ? "stepper-item current" : "stepper-item"} data-kt-stepper-element="nav">
                <div className="stepper-line w-40px"></div>
                <div className="stepper-icon w-40px h-40px">
                    <i className="stepper-check fas fa-check"></i>
                    <span className="stepper-number">2</span>
                </div>
                <div className="stepper-label">
                    <h4 className="stepper-title">Configure Checklist</h4>
                    <div className="stepper-desc ftw-bold">Cofigure Checklist For This Activation.</div>
                </div>
            </div>
            {/*end::Step 3*/}
        </div>
        {/*end::Nav*/}
    </div>
    {/*end::Wrapper*/}
    </div>
    {/*begin::Aside*/}
    {/*begin::Content*/}
    <div className="d-flex flex-row-fluid flex-center bg-body rounded">
    {/*begin::Form*/}
    <form className="py-20 w-100 w-xl-700px px-9 fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate" id="kt_create_account_form">
        {/*begin::Step 1*/}
        <div className={currentTab === 1 && "current"} data-kt-stepper-element="content">
            {/*begin::Wrapper*/}
            <div className="w-100">
                {/*begin::Heading*/}
                <div className="pb-2 pb-lg-2">
                    {/*begin::Title*/}
                    <h3 className="ftw-bolder d-flex align-items-center text-dark">Activation Details
                    <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="" data-bs-original-title="Billing is issued based on your selected account type" aria-label="Billing is issued based on your selected account type"></i></h3>
                    {/*end::Title*/}
                    {/*begin::Notice*/}
                    <div className="text-muted ftw-bold fs-6">Add basic details for your activation.</div>
                    {/*end::Notice*/}
                </div>
                {/*end::Heading*/}
                {/*begin::Input group*/}
                <div className='row mt-7 align-items-center'>
                    <div className='col-md-10'>
                    <label className="d-flex align-items-center fs-6 fwt-bold form-label mb-2">
                        <span>Is Beysix Studio the only handling or activating agency?</span>
                    </label>
                    </div>
                    <div className='col-md-2'>
                    <div className="form-check form-switch form-check-custom form-check-solid">
                        <input className="form-check-input" type="checkbox" checked={activationData.isBeysixActivatingAgency} onChange={() => {
                            setActivationData({...activationData, isBeysixActivatingAgency: !activationData.isBeysixActivatingAgency})
                        }}/>
                        <label className="form-check-label" for="flexSwitchDefault">
                            {activationData.isBeysixActivatingAgency ? <span className='fs-6 fwt-bold'>Yes</span> : <span className='fs-6 fwt-bold'>No</span>}
                        </label>
                    </div>
                    </div>
                </div>
                <div className="row"> 
                <div className="d-flex flex-column mt-3 fv-row col-md-6">
                    <label className="d-flex align-items-center fs-6 fwt-bold form-label mb-2">
                        <span className="required">Activation Name</span>
                        <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="Provide a unique name for the activation"></i>
                    </label>
                    <input autoComplete="off" type="text" className="form-control form-control-solid" placeholder="" name="activationName" value={activationData.activationName} onChange={handleChange} />
                    <div className="fv-plugins-message-container invalid-feedback">{errors.activationName}</div></div>
                <div className="d-flex flex-column mt-3 fv-row col-md-6">
                        <ProjectAssetsMultipleSearchComponent  returnResults = {(result) => {
                            if (result) {
                                const index  = activationData.activationProjects.some(project => project.id === result.id);
                                if (index === false){
                                    const res = {'name': result.project_name, 'id': result.id}
                                    setActivationData({...activationData, activationProjects: [...activationData.activationProjects, res]})
                                }
                            }
                        }} left={"75%"} successfullSubmission={successfullSubmission} inputName={'Add Activation Project(s)'} selectedProjects={activationData.activationProjects.map(project => project.id)} /> 
                        <div className="fv-plugins-message-container invalid-feedback">{errors.assetProject}</div>
                        </div>
                        {activationData.activationProjects.length > 0 &&
                        <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}} className='mt-3'>
                        {activationData.activationProjects.map((project, index) => { 
                            return (
                                <div key={index} className="badge badge-light-primary" style={{ display: 'inline-flex', alignItems: 'center', fontSize: '15px', marginRight: '15px', marginTop: '10px' }}>
                                    <span onClick={() => removeProjectById(project.id)} className="bi bi-x fs-3" style={{ marginRight: '15px', cursor: 'pointer' }}></span>
                                    <span>{project.name}</span>
                                </div>
                            )})}
                        </div>}
                <div className="d-flex flex-column mt-3 fv-row col-md-6">
                    <label className="d-flex align-items-center fs-6 fwt-bold form-label mb-2">
                        <span className="required">Loading Date</span>
                        <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="Loading date for activation"></i>
                    </label>
                    <input autoComplete="off" type="date" className="form-control form-control-solid" placeholder="" name="loadingDate" value={activationData.loadingDate} onChange={handleChange} />
                    <div className="fv-plugins-message-container invalid-feedback"></div></div>
                <div className="d-flex flex-column mt-3 fv-row col-md-6">
                    <label className="d-flex align-items-center fs-6 fwt-bold form-label mb-2">
                        <span className="required">Set-Up Date</span>
                        <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="Set-Up date for activation"></i>
                    </label>
                    <input autoComplete="off" type="date" className="form-control form-control-solid" placeholder="" name="setUpDate" value={activationData.setUpDate} onChange={handleChange} />
                <div className="fv-plugins-message-container invalid-feedback"></div></div>
                <div className="d-flex flex-column mt-3 fv-row col-md-6">
                    <label className="d-flex align-items-center fs-6 fwt-bold form-label mb-2">
                        <span className="required">Set-Down Date</span>
                        <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="Set-Down date for activation"></i>
                    </label>
                    <input autoComplete="off" type="date" className="form-control form-control-solid" placeholder="" name="setDownDate" value={activationData.setDownDate} onChange={handleChange} />
                <div className="fv-plugins-message-container invalid-feedback"></div></div>
                <div className="d-flex flex-column mt-3 fv-row col-md-6">
                    <label className="d-flex align-items-center fs-6 fwt-bold form-label mb-2">
                        <span className="required">Event Date</span>
                        <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="Set-Down date for activation"></i>
                    </label>
                    <input autoComplete="off" type="date" className="form-control form-control-solid" placeholder="" name="eventDate" value={activationData.eventDate} onChange={handleChange} />
                <div className="fv-plugins-message-container invalid-feedback"></div></div>
                <div className="d-flex flex-column mt-3 fv-row col-md-6">
                    <label className="d-flex align-items-center fs-6 fwt-bold form-label mb-2">
                        <span className="required">Activation Cost</span>
                        <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="Set-Down date for activation"></i>
                    </label>
                    <input autoComplete="off" type="number" className="form-control form-control-solid" placeholder="" name="activationCost" value={activationData.activationCost} onChange={handleChange} />
                <div className="fv-plugins-message-container invalid-feedback"></div></div>
                {!activationData.isBeysixActivatingAgency && 
                <>
                <div className="col-md-6 fv-row fv-plugins-icon-container mt-3">
                     <AgencySearchComponent returnResults = {(result) => {
                        const index = activationData.activatingAgencies.indexOf(result.id)
                            if (index === -1){
                                setSelectedAgencies((prevSelectedAgencies) => [...prevSelectedAgencies, result]);
                                setActivationData((prevActivationData) => ({
                                    ...prevActivationData,
                                    activatingAgencies: [...prevActivationData.activatingAgencies, result.id]
                                }));
                            }
                        }} successfullSubmission={successfullSubmission} inputLabel={'Activating Agency'} />
                    <div className="fv-plugins-message-container invalid-feedback"><div data-field="account_name" data-validator="notEmpty">{errors.brandAgencyError}</div></div>
                    {selectedAgencies.length > 0 &&
                        <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}} className='mt-3'>
                        {selectedAgencies.map((agency, index) => { 
                            return (
                                <div key={index} className="badge badge-light-primary" style={{ display: 'inline-flex', alignItems: 'center', fontSize: '15px', marginRight: '15px', marginTop: '10px' }}>
                                    <span onClick={() => removeActivatingAgencyById(agency.id)} className="bi bi-x fs-3" style={{ marginRight: '15px', cursor: 'pointer' }}></span>
                                    <span>{agency.name}</span>
                                </div>
                            )})}
                        </div>}
                </div>
                <div className="col-md-6 fv-row fv-plugins-icon-container mt-3">
                     <AgencySearchComponent returnResults = {(result) => {
                        const index = activationData.handlingAgencies.indexOf(result.id)
                            if (index === -1){
                                setSelectedHandlingAgencies((prevSelectedAgencies) => [...prevSelectedAgencies, result]);
                                setActivationData((prevActivationData) => ({
                                    ...prevActivationData,
                                    handlingAgencies: [...prevActivationData.handlingAgencies, result.id]
                                }));
                            }
                        }} successfullSubmission={successfullSubmission} inputLabel={'Handling Agency'} />
                    <div className="fv-plugins-message-container invalid-feedback"><div data-field="account_name" data-validator="notEmpty">{errors.brandAgencyError}</div></div>
                    {selectedHandlingAgencies.length > 0 &&
                        <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}} className='mt-3'>
                        {selectedHandlingAgencies.map((agency, index) => { 
                            return (
                                <div key={index} className="badge badge-light-primary" style={{ display: 'inline-flex', alignItems: 'center', fontSize: '15px', marginRight: '15px', marginTop: '10px' }}>
                                    <span onClick={() => removeHandlingAgencyById(agency.id)} className="bi bi-x fs-3" style={{ marginRight: '15px', cursor: 'pointer' }}></span>
                                    <span>{agency.name}</span>
                                </div>
                            )})}
                        </div>}
                </div></>}
                <div className="fv-row fv-plugins-icon-container mt-3">
                    <label className='d-flex align-items-center form-label mb-3'>Select Activation Location: </label>
                        <MapContainer
                            ref={mapRef}
                            center={initCoordinates}
                            zoom={14}
                            style={{ height: "250px", width: "100%" }}
                            onClick={handleMapClick}
                        >
                        <MapEventsHandler setMarkerPosition={setMarkerPosition} />
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        {markerPosition && (
                            <Marker position={markerPosition} icon={customIcon} ref={marker}>
                                <Popup>
                                    {activationData.activationName}: {markerPosition.lat.toFixed(4)}, {markerPosition.lng.toFixed(4)}
                                </Popup>
                            </Marker>
                        )}
                        </MapContainer>
                </div>   
                <div className='row'>
                    <div className='col-lg-6 mt-3'>
                        <label className='d-flex align-items-center form-label'>Enter coordinates (manually): </label>
                        <input value={activationData.activationLocationLatitude !== "" && activationData.activationLocationLongitude !== "" ? `${parseFloat(activationData.activationLocationLatitude).toFixed(4)}, ${parseFloat(activationData.activationLocationLongitude).toFixed(4)}` : ''} className = "form-control form-control-lg form-control-solid" name="warehouse_name" onChange={handleManualCoordinatesChange} />
                        {errors.activationCoordinatesError && (errors.activationCoordinatesError.length > 0 && <div className="fv-plugins-message-container invalid-feedback"><div data-field="account_name" data-validator="notEmpty">{errors.activationCoordinatesError}</div></div>)}
                    </div>
                    <div className="d-flex flex-column mt-3 fv-row col-md-6">
                    <label className="d-flex align-items-center fs-6 fwt-bold form-label mb-2">
                        <span className="required">Street Name or Location Name: </span>
                        <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="Provide a unique name for the asset"></i>
                    </label>
                    <input autoComplete="off" type="text" className="form-control form-control-solid" placeholder="" name="locationName" value={activationData.locationName} onChange={handleChange} />
                    </div>
                    <div className="d-flex flex-column mt-3 fv-row col-md-6">
                        <CountySearchComponent returnResults = {(id) => {
                            setActivationData({...activationData, countyId: id})
                        }} successfullSubmission={successfullSubmission} />
                    </div>
                    <div className="d-flex flex-column mt-3 fv-row col-md-6">
                        <RegionSearchComponent returnResults = {(id) => {
                            setActivationData({...activationData, regionId: id})
                        }} successfullSubmission={successfullSubmission} />
                    </div>
                </div>
                <div className="mb-7 mt-3 fv-row col-md-12">
                    <label className="d-flex align-items-center fs-6 fwt-bold form-label mb-2">Comment</label>
                    <textarea name="activationDescription" rows="10" cols="30" className='form-control form-control-solid' value={activationData.activationDescription} onChange={handleChange}></textarea>
                </div>
                </div>
                {/*end::Input group*/}
            </div>
            {/*end::Wrapper*/}
        </div>
        {/*end::Step 1*/}
        {/*begin::Step 2*/}
        <div className={currentTab === 2 && "current"} data-kt-stepper-element="content">
            {/*begin::Wrapper*/}
            <div className="w-100">
            <div className="d-flex flex-column mt-7 fv-row col-md-12">
                    
                    <ProjectAssetsMultipleSearchComponent  returnResults = {(result) => {
                        if (result) {
                            setSelectedProjects(prevSelectedProjects => [...prevSelectedProjects, result]);
                            setSelectedProjectsIds([...selectedProjectsIds, result.id])
                        }
                    }} successfullSubmission={successfullSubmission} inputName={'Configure Checklist'} selectedProjects={selectedProjectsIds} left={'79%'} /> 
                    
                    <div className='passets-accordion'>
                    <ul className="Wrapper">
                    {selectedProjects.length > 0 &&
                        selectedProjects.map((project, projectIndex) => {
                            return (<div key={projectIndex} style={{marginTop: '30px'}}>
                                {/* Project checkbox */}
                                <ul>
                                    <li className="mt-3 d-flex justify-content-between align-items-center">
                                        <label className="form-check form-check-sm form-check-custom form-check-solid" style={{ flexBasis: '20%', minWidth: '40%'}}>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={isProjectChecked(project.id, projectIndex)} // Assuming you have a function to check the project selection
                                                onChange={() => handleProjectCheck(project.id, projectIndex)} // Assuming you have a handler for project selection
                                            />
                                            <span className="form-check-label fs-6 ftw-bold">{project.project_name} (Project)</span> {/* Displaying the project name */}
                                        </label>
                                        <span onClick = {() => handleRemoveProject(projectIndex)} className="remove-item-btn" style={{background: '#fac1cf'}}><i className="bi bi-x" style={{fontSize: "2.0rem", color: '#f1416c'}}></i></span>
                                    </li>
                                <div style={{maxHeight: '400px', overflow: 'auto', marginTop: '15px', border: '1px solid #fcc282', padding: '10px', borderRadius: '10px'}}>
                                    {/* Asset checkboxes */}
                                    {project.assets_items.map((asset, assetIndex) => (
                                    <>
                                        <ul key={asset.id || assetIndex}>
                                            <li>
                                                <label className="form-check form-check-sm form-check-custom form-check-solid" style={{ flexBasis: '20%', marginTop: '12px', marginRight: '19px' }}>
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={isAssetChecked(project.id, asset.asset_id, projectIndex)}
                                                        onChange={() => handleAssetCheck(project.id, asset.asset_id, projectIndex)}
                                                    />
                                                    <span className="form-check-label fs-6 ftw-bold">{asset.asset_name} (Asset)</span>
                                                </label>
                                            </li>

                                            <div className="Accordion-panel">
                                                <ul className="Checkbox-child">
                                                    {asset.items.map((item, index) => (
                                                        <li key={index} className="mt-1">
                                                            <label className="form-check form-check-sm form-check-custom form-check-solid" style={{ flexBasis: '20%', marginTop: '12px', marginRight: '19px' }}>
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    checked={checkedItems[project.id]?.[asset.asset_id]?.[item.item_id] || false}
                                                                    onChange={() => handleItemCheck(project.id, asset.asset_id, item.item_id)}
                                                                />
                                                                <span className="form-check-label fs-6 ftw-bold text-gray-800">{item.item_name}</span>
                                                            </label>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </ul>
                                        {(assetIndex + 1 < project.assets_items.length) && <hr style={{width: '90%', margin: '0 auto', marginTop: '15px', marginBottom: '15px'}} />}
                                    </>
                                    ))}
                                </div>
                                </ul>
                            </div>
                        )})
                    }

                        </ul>
                    </div>
                </div>
            </div>
        </div>
        {/*end::Step 2*/}
        {/*begin::Actions*/}
        <div className="d-flex flex-stack pt-10">
            {/*begin::Wrapper*/}
            <div className="mr-2">
                {currentTab > 1 && <button type="button" className="btn btn-sm btn-light-primary me-3" onClick={() => setCurrentTab(currentTab - 1)}>
                {/*begin::Svg Icon | path: icons/duotune/arrows/arr063.svg*/}
                <span className="svg-icon svg-icon-4 me-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect opacity="0.5" x="6" y="11" width="13" height="2" rx="1" fill="black"></rect>
                        <path d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z" fill="black"></path>
                    </svg>
                </span>
                {/*end::Svg Icon*/}Back</button>}
            </div>
            {/*end::Wrapper*/}
            {/*begin::Wrapper*/}
            <div>
                {currentTab === maxTab && <button type="button" onClick={handleSubmit} className="btn btn-sm btn-beysix me-3" disabled={submitLoading ? true : false}>
                    {!submitLoading ? <span className="indicator-label">Submit
                    {/*begin::Svg Icon | path: icons/duotune/arrows/arr064.svg*/}
                    <span className="svg-icon svg-icon-3 ms-2 me-0 svg-icon-white">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="black"></rect>
                            <path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="black"></path>
                        </svg>
                    </span>
                    {/*end::Svg Icon*/}</span> : 
                    <span className="indicator-progress">Please wait...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>}
                </button>}
                {currentTab < maxTab && <button type="button" onClick={() => handleContinue()} className="btn btn-sm btn-beysix" data-kt-stepper-action="next">Continue
                {/*begin::Svg Icon | path: icons/duotune/arrows/arr064.svg*/}
                <span className="svg-icon svg-icon-4 ms-1 me-0">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="black"></rect>
                        <path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="black"></path>
                    </svg>
                </span>
                {/*end::Svg Icon*/}</button>}
            </div>
            {/*end::Wrapper*/}
        </div>
        {/*end::Actions*/}
    <div></div><div></div><div></div><div></div></form>
    {/*end::Form*/}
    </div>
    {/*end::Content*/}
    </div>
  )
}
