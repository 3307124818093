import React, {useEffect, useState} from 'react'
import { useSelector } from 'react-redux'
import api from '../../../store/api'
import { useParams } from 'react-router'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

export default function ActivationChecklists() {

    const [checklists, setChecklists] = useState([])
    const token = useSelector((state) => state.loginSlice.token);
    const { id } = useParams()
    const [isOpen, setIsOpen] = useState({});
    const [currentAssetIndex, setCurrentAssetIndex] = useState(null);
    const [lightboxImageIndex, setLightboxImageIndex] = useState(0);

    const getChecklist = () => {
        api.get(`/api/activations/activation-checklist/${id}/`,
            {headers: {'Content-Type': 'application/json', Authorization: `Token ${token}`}})
            .then((response) => {
                if (response.status === 200) {
                    const data = response.data.checklists
                    if (data){
                        setChecklists(data)
                    }
                }
            })
            .catch((error) => {
                throw new Error('Error getting data.')
            })
    }

    useEffect(() => {
        getChecklist()
    }, [])


  return (
    <>
    <div
      className="card paper-table hover-card"
      style={{ marginTop: "30px", padding: "50px 125px" }}
    >
    {checklists && (
        checklists.map((checklist, checklistIndex) => (
            checklist.assets.length > 0 &&
        <>
            <table key={checklistIndex} style={{marginBottom: "30px"}}>
            <thead>
                <tr>
                <th className="min-w-125px">Asset</th>
                <th className="min-w-250px">Items</th>
                <th className="min-w-125px">Condition</th>
                <th className="min-w-125px">Recent Images</th>
                </tr>
            </thead>
            <tbody>
                {checklist.assets.map((asset, assetIndex) => (
                    <React.Fragment key={assetIndex}>
                    {/* First Row: Asset Name and First Item */}
                    <tr>
                        <td
                        rowSpan={asset.item_serials.length}
                        style={{ verticalAlign: "top" }}
                        >
                        {asset.asset_name}
                        </td>
                        <td>{asset.item_serials[0]?.serial_name}</td>
                        <td>{asset.item_serials[0]?.serial_condition}</td>
                        <td rowSpan={asset.item_serials.length}>
                        {asset.recent_images?.length > 0 && (
                            <a
                            className="menu-link px-3 menu-rounded menu-gray-800 menu-state-bg-light-primary ftw-bold"
                            style={{ color: "#fa890f" }}
                            onClick={() => {
                                setCurrentAssetIndex(assetIndex); 
                                setLightboxImageIndex(0); 
                                setIsOpen({ ...isOpen, [checklistIndex]: true });
                            }}
                            >
                            View Images
                            </a>
                        )}
                        </td>
                    </tr>
                    {/* Additional Rows: Remaining Items */}
                    {asset.item_serials.slice(1).map((item, itemIndex) => (
                        <tr key={itemIndex}>
                        <td>{item.serial_name}</td>
                        <td>{item.serial_condition}</td>
                        </tr>
                    ))}
                    </React.Fragment>
                ))}
            </tbody>
            </table>
            {isOpen[checklistIndex] && currentAssetIndex !== null && (
                <Lightbox
                    mainSrc={
                        checklist?.assets[currentAssetIndex]?.recent_images?.[lightboxImageIndex]?.url || ''
                    }
                    nextSrc={
                        checklist?.assets[currentAssetIndex]?.recent_images?.[
                            (lightboxImageIndex + 1) %
                            (checklist?.assets[currentAssetIndex]?.recent_images?.length || 1)
                        ]?.url || ''
                    }
                    prevSrc={
                        checklist?.assets[currentAssetIndex]?.recent_images?.[
                            (lightboxImageIndex +
                                (checklist?.assets[currentAssetIndex]?.recent_images?.length || 1) -
                                1) %
                            (checklist?.assets[currentAssetIndex]?.recent_images?.length || 1)
                        ]?.url || ''
                    }
                    onCloseRequest={() =>
                        setIsOpen((prev) => ({ ...prev, [checklistIndex]: false }))
                    }
                    onMovePrevRequest={() =>
                        setLightboxImageIndex(
                            (lightboxImageIndex +
                                (checklist?.assets[currentAssetIndex]?.recent_images?.length || 1) -
                                1) %
                            (checklist?.assets[currentAssetIndex]?.recent_images?.length || 1)
                        )
                    }
                    onMoveNextRequest={() =>
                        setLightboxImageIndex(
                            (lightboxImageIndex + 1) %
                            (checklist?.assets[currentAssetIndex]?.recent_images?.length || 1)
                        )
                    }
                    imageCaption={
                        <div style={{ padding: "10px", color: "#fff" }}>
                            {
                                checklist?.assets[currentAssetIndex]?.recent_images?.[
                                    lightboxImageIndex
                                ]?.comment
                            }
                        </div>
                    }
                />
            )}
        </>
      )))}
      </div>
    </>
  )
}
