import React, {useState, useEffect, useRef} from 'react'
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet';
import "leaflet/dist/leaflet.css";
import L from 'leaflet';
import 'leaflet-search/dist/leaflet-search.min.css';
import 'leaflet-search';
import { useSelector } from 'react-redux';
import api from '../../../store/api';
import getRandomHexColor from '../../Utilities/getRandomHexColor';
import FeedbackModal from '../../Utilities/FeedbackModal';
import ProfileImageInput from '../../Utilities/ProfileImageInput';

export default function EditWarehouse({active, warehouseData, setWarehouseData}) {
    const [warehouseDetails, setWarehouseDetails] = useState({
        warehouse_name: "",
        Location_longitude: "",
        Location_latitude: "",
        warehouse_capacity: "",
        warehouse_description: "",
        warehouse_manager: "",
        warehouse_image: null,
        warehouse_contact: ""
    })

    const handleSetMarkerPosition = (coordinates) => {
        const { lat, long } = coordinates
        if (lat && long) {
            setMarkerPosition({lat: parseFloat(lat), lng: parseFloat(long)})
            const marker = L.marker([51.505, -0.09], { icon: customIcon }).addTo(mapRef.current);
            marker.setLatLng([lat, long]);
            const newLatLng = marker._latlng;
            mapRef.current.flyTo(newLatLng, 14);
        }
    }

    useEffect(() => {
        setWarehouseDetails({
            warehouse_name: warehouseData.Warehouse_name,
            Location_longitude: warehouseData.Location_longitude,
            Location_latitude: warehouseData.Location_latitude,
            warehouse_capacity: warehouseData.Warehouse_capacity,
            warehouse_description: warehouseData.Warehouse_description,
            warehouse_manager: warehouseData.Warehouse_manager,
            warehouse_image: warehouseData.warehouse_manager_image_path,
            warehouse_contact: warehouseData.Warehouse_contact
        })
        setDisplayImage(warehouseData.warehouse_image_path)
        setSearchQuery(`${warehouseData.warehouse_manager_name}`)
    }, [warehouseData])

    const [errors, setErrors] = useState({
        warehouse_name: "",
        Location_longitude: "",
        Location_latitude: "",
        warehouse_capacity: "",
        warehouse_description: "",
        warehouse_manager: "",
        warehouse_image: "",
        warehouse_contact: ""
    })

    const initCoordinates = [-1.2460227009959508, 36.68168221244762];
    const marker = useRef(null)
    const mapRef = useRef(null)
    const [markerPosition, setMarkerPosition] = useState(null);
    const onMapReady = (map) => {
        map = map.current
        if (map){
            map.invalidateSize()
        }
    };
    const [displayImage, setDisplayImage] = useState("/static/assets/media/illustrations/beysix-1/warehouse-placeholder.png")
    const maxStep = 2
    const [currentStep, setCurrentStep] = useState(0)
    const [coordinates, setCoordinates] = useState(null);
    const [searchQuery, setSearchQuery] = useState("")
    const [searchResults, setSearchResults] = useState([])
    const [isSearching, setIsSearching] = useState(false)
    const [userSearching, setUserSearching] = useState(false)
    const [resultsFound, setResultsFound] = useState(false)
    const [loading, setLoading] = useState(false)
    const token = useSelector((state) => state.loginSlice.token);
    const handleMapClick = (e) => {
        setCoordinates(e.latlng);
    };
    const [res, setRes] = useState({
        message: "",
        severity: "success",
        show: false
    })

    const handleSearch = (evt) => {
        evt.preventDefault()
        const query = evt.target.value;
            setSearchQuery(query)
            if (query.length >= 2) {
                setUserSearching(true)
                setIsSearching(true)
                api.post(`/api/users/search-user/staff/`, {query: query},
                {headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Token ${token}`,
                    },
                })
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data.results.length > 0){
                            setResultsFound(true)
                            setSearchResults(response.data.results)
                            setIsSearching(false)
                        } else {
                            setResultsFound(false)
                            setSearchResults(response.data.results)
                            setIsSearching(false)
                        }
                    }
                })
                .catch((error) => {
                    if (response.status === 404) {

                    } else {
                        throw new Error(error)
                    }
                })
            } else if (query.length === 0) {
                setResultsFound(false)
                setIsSearching(false)
                setUserSearching(false)
            }
    }

    const handleImageChange = (event) => {
        event.preventDefault()
        const imageFile = event.target.files[0];
        if (imageFile && imageFile.type.startsWith('image/')) {
            const imagePath = URL.createObjectURL(imageFile);
            setWarehouseDetails({...warehouseDetails, warehouse_image: imageFile});
            setDisplayImage(imagePath)
        } else {
          throw new Error('Invalid file type. Please select an image file.');
        }
    }

    const handleChange = (event) => {
        event.preventDefault()
        const name = event.target.name
        const value = event.target.value
        setWarehouseDetails({...warehouseDetails, [name]: value})
    }

    const handleRemoveImage = (evt) => {
        evt.preventDefault()
        setDisplayImage("/static/assets/media/illustrations/beysix-1/warehouse-placeholder.png")
        setWarehouseDetails({...warehouseDetails, warehouse_image: ""});
    }

    function validateForm(step) {
        let valid = false
        if (step === 0){
            if (warehouseDetails.warehouse_name.length > 0){
                valid = true
            } else {
                setErrors({...errors, warehouse_name: "You must enter the name of the warehouse."})
            }
        }
        else if (step === 1) {
            valid = true
        }
        else if (step === 2){
            valid = true
        }
        return valid
    }

    const handleContinue = (evt) => {
        evt.preventDefault()
        if (validateForm(currentStep)) {
            setCurrentStep(currentStep + 1)
            if (currentStep === 0){
              setTimeout(() => {
                onMapReady(mapRef);
                handleSetMarkerPosition({lat: warehouseData.Location_latitude, long: warehouseData.Location_longitude})
              }, 5);
            }
        }
    }

    const MapEventsHandler = ({ setMarkerPosition }) => {
        const map = useMapEvents({
          click: (e) => {
            setMarkerPosition(e.latlng);
            handleUpdateCoordinates(e.latlng.lat, e.latlng.lng)
          }
        });

        return null;
    };

    const customIcon = L.icon({
        iconUrl: '/static/assets/media/illustrations/beysix-1/location-pin.png',
        iconSize: [38, 38],
        iconAnchor: [19, 38],
        popupAnchor: [0, -38]
    });

    function handleUpdateCoordinates(lat, long) {
        setWarehouseDetails({...warehouseDetails, Location_latitude: lat, Location_longitude: long})
    }

    const handleSplitCoordinates = (input) => {
        const regex = /(-?\d+(\.\d+)?)\s*[,;|\s]\s*(-?\d+(\.\d+)?)/;
        const match = input.match(regex);
        if (match) {
            const latitude = parseFloat(match[1]);
            const longitude = parseFloat(match[3]);
            if (latitude >= -90 && latitude <= 90 && longitude >= -180 && longitude <= 180) {
            return [latitude, longitude];
            } else {
                return false
            }
        } else {
            return false
        }
    }

    const handleManualCoordinatesChange = (evt) => {
        const value = evt.target.value
        const coords = handleSplitCoordinates(value)
        if (coords !== false) {
            setWarehouseDetails({...warehouseDetails, Location_latitude: coords[0], Location_longitude: coords[1]})
            setMarkerPosition({lat: parseFloat(coords[0]), lng: parseFloat(coords[1])})
            const marker = L.marker([51.505, -0.09], { icon: customIcon }).addTo(mapRef.current);
            marker.setLatLng([coords[0], coords[1]]);
            const newLatLng = marker._latlng;
            mapRef.current.flyTo(newLatLng, 14);
        }
    }

    const handleCancelSearch = (evt) => {
        evt.preventDefault()
        setResultsFound(false)
        setIsSearching(false)
        setUserSearching(false)
        setSearchResults([])
        setResultsFound(false)
        setSearchQuery("")
    }

    const handleManagerClick = (id, name) => {
        setWarehouseDetails({...warehouseDetails, warehouse_manager: id})
        setResultsFound(false)
        setIsSearching(false)
        setUserSearching(false)
        setSearchResults([])
        setResultsFound(false)
        setSearchQuery(name)
    }

    const handleSubmit = (evt) => {
        evt.preventDefault()
        setLoading(true)
        const data = warehouseDetails
        if (data) {
            const formData = new FormData();
            formData.append('warehouse_name', data.warehouse_name)
            formData.append('Location_longitude', data.Location_longitude)
            formData.append('Location_latitude', data.Location_latitude)
            formData.append('warehouse_capacity', data.warehouse_capacity)
            formData.append('warehouse_description', data.warehouse_description)
            formData.append('warehouse_manager', data.warehouse_manager)
            formData.append('warehouse_image', data.warehouse_image)
            formData.append('warehouse_contact', data.warehouse_contact)

            api.put(`/api/warehouses/update-warehouse/${warehouseData.id}/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Token ${token}`,
                },
            })
            .then(response => {
                const data = response.data.data
                setRes({
                    message: response.data.message,
                    severity: "success",
                    show: true
                })
                setResultsFound(false)
                setIsSearching(false)
                setUserSearching(false)
                setSearchResults([])
                setResultsFound(false)
                setSearchQuery(data.warehouse_manager_name)
                setCurrentStep(0)
                setWarehouseDetails({
                    warehouse_name: data.Warehouse_name,
                    Location_longitude: data.Location_longitude,
                    Location_latitude: data.Location_latitude,
                    warehouse_capacity: data.Warehouse_capacity,
                    warehouse_description: data.Warehouse_description,
                    warehouse_manager: data.Warehouse_manager,
                    warehouse_image: data.warehouse_image_path,
                    warehouse_contact: data.Warehouse_contact
                })
                setDisplayImage(data.warehouse_image_path)
                setErrors({
                    warehouse_name: "",
                    Location_longitude: "",
                    Location_latitude: "",
                    warehouse_capacity: "",
                    warehouse_description: "",
                    warehouse_manager: "",
                    warehouse_image: "",
                    warehouse_contact: ""
                })
                setWarehouseData(data)
                setLoading(false)
            })
            .catch(error => {
                setRes({
                    message: error.response.data.error,
                    severity: "error",
                    show: true
                })
                setLoading(false)
            });
        }
    }

  return (<>
        {active && <div className="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid" id="kt_create_account_stepper" data-kt-stepper="true">
        <FeedbackModal message={res.message} severity = {res.severity} show = {res.show} onClose = {() => {
			setRes({
				message: "",
				severity: "success",
				show: false
			})
		}}/>
        <div className="d-flex justify-content-center bg-body rounded justify-content-xl-start flex-row-auto me-9">
            {/*begin::Wrapper*/}
            <div className="px-6 px-lg-10 px-xxl-15 py-20">
                {/*begin::Nav*/}
                <div className="stepper-nav">
                    {/*begin::Step 1*/}
                    <div className={currentStep === 0 ? "stepper-item current" : "stepper-item"} data-kt-stepper-element="nav">
                        {/*begin::Line*/}
                        <div className="stepper-line w-40px"></div>
                        {/*end::Line*/}
                        {/*begin::Icon*/}
                        <div className="stepper-icon w-40px h-40px">
                            <i className="stepper-check fas fa-check"></i>
                            <span className="stepper-number">1</span>
                        </div>
                        {/*end::Icon*/}
                        {/*begin::Label*/}
                        <div className="stepper-label">
                            <h3 className="stepper-title">Warehouse Name</h3>
                            <div className="stepper-desc fwt-bold">Setup basic warehouse details</div>
                        </div>
                        {/*end::Label*/}
                    </div>
                    {/*end::Step 1*/}
                    {/*begin::Step 2*/}
                    <div className={currentStep === 1 ? "stepper-item current" : "stepper-item"} data-kt-stepper-element="nav">
                        {/*begin::Line*/}
                        <div className="stepper-line w-40px"></div>
                        {/*end::Line*/}
                        {/*begin::Icon*/}
                        <div className="stepper-icon w-40px h-40px">
                            <i className="stepper-check fas fa-check"></i>
                            <span className="stepper-number">2</span>
                        </div>
                        {/*end::Icon*/}
                        {/*begin::Label*/}
                        <div className="stepper-label">
                            <h3 className="stepper-title">Warehouse Location</h3>
                            <div className="stepper-desc fwt-bold">Setup warehouse location</div>
                        </div>
                        {/*end::Label*/}
                    </div>
                    {/*end::Step 2*/}
                    {/*begin::Step 3*/}
                    <div className={currentStep === 2 ? "stepper-item current" : "stepper-item"} data-kt-stepper-element="nav">
                        {/*begin::Line*/}
                        <div className="stepper-line w-40px"></div>
                        {/*end::Line*/}
                        {/*begin::Icon*/}
                        <div className="stepper-icon w-40px h-40px">
                            <i className="stepper-check fas fa-check"></i>
                            <span className="stepper-number">3</span>
                        </div>
                        {/*end::Icon*/}
                        {/*begin::Label*/}
                        <div className="stepper-label">
                            <h3 className="stepper-title">Warehouse Details</h3>
                            <div className="stepper-desc fwt-bold">Setup detailed warehouse information</div>
                        </div>
                        {/*end::Label*/}
                    </div>
                    {/*end::Step 3*/}
                </div>
                {/*end::Nav*/}
            </div>
            {/*end::Wrapper*/}
        </div>
        {/*begin::Aside*/}
        {/*begin::Content*/}
        <div className="d-flex flex-row-fluid flex-center bg-body rounded">
            {/*begin::Form*/}
            <form onSubmit = {handleSubmit} className="py-10 w-100 w-xl-700px px-9 fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate" id="kt_create_account_form">
                {/*begin::Step 1*/}
                <div className={currentStep === 0 && "current"} data-kt-stepper-element="content">
                    {/*begin::Wrapper*/}
                    <div className="w-100">
                        {/*begin::Heading*/}
                        <div className="pb-10 pb-lg-15">
                            {/*begin::Title*/}
                            <h2 className="fwt-bolder d-flex align-items-center text-dark">warehouse Details
                            <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="" data-bs-original-title="Billing is issued based on your selected account type" aria-label="Billing is issued based on your selected account type"></i></h2>
                            {/*end::Title*/}
                            {/*begin::Notice*/}
                            <div className="text-muted fwt-bold fs-6">Setup Basic warehouse Details</div>
                            {/*end::Notice*/}
                        </div>
                        {/*end::Heading*/}
                        {/*begin::Input group*/}
                        <div className="fv-row fv-plugins-icon-container">
                            {/*begin::Row*/}
                            <div className="row">
                                <label className='d-flex align-items-center form-label mb-3'>warehouse Image: </label>
                                <div className="col-lg-6">
                                <ProfileImageInput setImage={(image) => {
                                    setWarehouseDetails({...warehouseDetails, warehouse_image: image});
                                }} edit={true} image={warehouseData.warehouse_image_path} 
                                 />
                                {errors.warehouse_image.length > 0 && <div className="fv-plugins-message-container invalid-feedback"><div data-field="account_name" data-validator="notEmpty">{errors.warehouse_image}</div></div>}
                                </div>
                            </div>
                            <div className='row mt-5'>
                                <div className='col-lg-6'>
                                    <label className='d-flex align-items-center form-label mb-3'>warehouse Name: </label>
                                    <input className = "mt-1 form-control form-control-lg form-control-solid" name="warehouse_name" onChange={handleChange} value={warehouseDetails.warehouse_name}/>
                                    {errors.warehouse_name.length > 0 && <div className="fv-plugins-message-container invalid-feedback"><div data-field="account_name" data-validator="notEmpty">{errors.warehouse_name}</div></div>}
                                </div>
                                <div className='col-lg-6'>
                                    <label className='d-flex align-items-center form-label mb-3'>warehouse Official Phone Number (Contact): </label>
                                    <input className = "mt-1 form-control form-control-lg form-control-solid" name="warehouse_contact" onChange={handleChange} value={warehouseDetails.warehouse_contact}/>
                                    {errors.warehouse_contact.length > 0 && <div className="fv-plugins-message-container invalid-feedback"><div data-field="account_name" data-validator="notEmpty">{errors.warehouse_contact}</div></div>}
                                </div>
                            </div>
                            {/*end::Row*/}
                        </div>
                        {/*end::Input group*/}
                    </div>
                    {/*end::Wrapper*/}
                </div>
                {/*end::Step 1*/}
                {/*begin::Step 2*/}
                <div className={currentStep === 1 && "current"} data-kt-stepper-element="content">
                    {/*begin::Wrapper*/}
                    <div className="w-100">
                    <div className="pb-10 pb-lg-15">
                        {/*begin::Title*/}
                        <h2 className="fwt-bolder d-flex align-items-center text-dark">Warehouse Location
                        <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="" data-bs-original-title="Billing is issued based on your selected account type" aria-label="Billing is issued based on your selected account type"></i></h2>
                        {/*end::Title*/}
                        {/*begin::Notice*/}
                        <div className="text-muted fwt-bold fs-6">Setup Warehouse Location Details.</div>
                        {/*end::Notice*/}
                    </div>

                    <label className='d-flex align-items-center form-label mb-3'>Select Warehouse Coordinates: </label>
                        <MapContainer
                            ref={mapRef}
                            center={initCoordinates}
                            zoom={14}
                            style={{ height: "400px", width: "100%" }}
                            onClick={handleMapClick}
                        >
                        <MapEventsHandler setMarkerPosition={setMarkerPosition} />
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        {markerPosition && (
                            <Marker position={markerPosition} icon={customIcon} ref={marker}>
                                <Popup>
                                    {warehouseDetails.warehouse_name}: {markerPosition.lat.toFixed(4)}, {markerPosition.lng.toFixed(4)}
                                </Popup>
                            </Marker>
                        )}
                        </MapContainer>
                        <div className='col-lg-12 mt-3'>
                            <label className='d-flex align-items-center form-label mb-3'>Enter coordinates (manually): </label>
                            <input value={warehouseDetails.Location_latitude !== "" && warehouseDetails.Location_longitude !== "" ? `${parseFloat(warehouseDetails.Location_latitude).toFixed(4)}, ${parseFloat(warehouseDetails.Location_longitude).toFixed(4)}` : ''} className = "mt-1 form-control form-control-lg form-control-solid" name="warehouse_name" onChange={handleManualCoordinatesChange} />
                            {(errors.Location_latitude.length > 0 && errors.Location_longitude > 0) && <div className="fv-plugins-message-container invalid-feedback"><div data-field="account_name" data-validator="notEmpty">{errors.Location_latitude} and {errors.Location_longitude}</div></div>}
                        </div>
                    </div>
                </div>
                {/*end::Step 2*/}
                {/*begin::Step 3*/}
                <div className={currentStep === 2 && "current"} data-kt-stepper-element="content">
                    {/*begin::Wrapper*/}
                    <div className="w-100">
                        {/*begin::Heading*/}
                        <div className="pb-10 pb-lg-12">
                            {/*begin::Title*/}
                            <h2 className="fwt-bolder text-dark">Warehouse details</h2>
                            {/*end::Title*/}
                            {/*begin::Notice*/}
                            <div className="text-muted fwt-bold fs-6">Setup up detailed information about the warehouse.</div>
                            {/*end::Notice*/}
                        </div>
                        {/*end::Heading*/}
                        {/*begin::Input group*/}
                        <div className="fv-row mb-10 fv-plugins-icon-container">
                            {/*begin::Label*/}
                            <label className="form-label required">Warehouse Manager</label>
                            {/*end::Label*/}
                            <div>
                            <div>
                                {/*begin::Search*/}
                                <div id="kt_docs_search_handler_basic" data-kt-search-keypress="true" data-kt-search-min-length="2" data-kt-search-enter="true" data-kt-search-layout="inline" data-kt-search="true" className="">
                                    <input type="hidden" />
                                    <div style={{position: 'relative'}}>
                                    <span className="svg-icon svg-icon-2 svg-icon-lg-1 svg-icon-gray-500" style={{position: "absolute", left: "2%", top: '25%'}}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="black"></rect>
                                            <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="black"></path>
                                        </svg>
                                    </span>
                                        <input type="text" className="form-control form-control-lg form-control-solid px-15" name="search" value={searchQuery} placeholder="Search by username, full name or email..." onChange={handleSearch} />
                                        {isSearching ? <span className="lh-0 me-5" data-kt-search-element="spinner" style={{position: "absolute", top: "35%", left: "94%"}}>
                                            <span className="spinner-border h-15px w-15px align-middle text-gray-400"></span>
                                        </span> :
                                        searchQuery.length > 0 && 
                                        <span className="btn btn-flush btn-active-color-primary end-0 lh-0" style={{position: "absolute", top: "25%", left: "93%"}} data-kt-search-element="clear" onClick={handleCancelSearch}>
                                            <span className="svg-icon svg-icon-2 svg-icon-lg-1">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black"></rect>
                                                    <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black"></rect>
                                                </svg>
                                            </span>
                                        </span>}
                                {userSearching && 
                                <div className="quick-search-dropdown" style={{position: "absolute"}}>
                                    {searchResults.length > 0 && resultsFound ? <div data-kt-search-element="results" className="">
                                    {searchResults.map((result, index) => {
                                    const { randomColor, darkerShade, brighterShade } = getRandomHexColor();
                                    return (
                                    <div className="d-flex align-items-center p-3 rounded-3 border-hover border border-dashed border-gray-300 cursor-pointer mb-1"key = {index} onClick={() => handleManagerClick(result.id, result.profile && `${result.profile.first_name} ${result.profile.last_name}`)}>
                                        <div className="symbol symbol-35px symbol-circle me-5">
                                            {result.profile && (result.profile.profile_photo_path !== "" && result.profile.profile_photo_path !== null) ?
                                            <img alt="Pic" src={result.profile.profile_photo_path} style={{objectFit: "cover"}}/> :
                                            result.profile && <div className="symbol-label fs-5" style={{ background: brighterShade, color: darkerShade }}>{result.profile.first_name[0]}{result.profile.last_name[0]}</div>}
                                        </div>
                                        <div className="fw-bold">
                                            <span className="fs-6 text-gray-800 me-2">{result.profile && `${result.profile.first_name} ${result.profile.last_name}`}</span>
                                            <span className="badge badge-light">{result.profile && result.profile.occupation}</span>
                                        </div>
                                    </div>)})}
                                    </div> :
                                    <div data-kt-search-element="empty" className="text-center">
                                        {/*begin::Message*/}
                                        <div className="fwt-bold py-0 mb-10">
                                            <div className="text-gray-600 fs-3 mb-2">No users found</div>
                                            <div className="text-gray-400 fs-6">Try to search by username, full name or email...</div>
                                        </div>
                                        {/*end::Message*/}
                                        {/*begin::Illustration*/}
                                        <div className="text-center px-4">
                                            <img className="mw-100 mh-200px" alt="" src="assets/media/illustrations/alert.png" />
                                        </div>
                                        {/*end::Illustration*/}
                                    </div>}
                                    {/*end::Empty*/}
                                </div>}
                                {/*end::Wrapper*/}
                                            </div>
                                            {/*end::Search*/}
											</div>
										</div>
                                        </div>
                        <div className="fv-plugins-message-container invalid-feedback"></div></div>
                        {/*end::Input group*/}
                        {/*begin::Input group*/}
                        <div className="fv-row mb-10 fv-plugins-icon-container">
                            {/*begin::Label*/}
                            <label className="d-flex align-items-center form-label">
                                <span className="required">Warehouse Capacity</span>
                                <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="popover" data-bs-trigger="hover" data-bs-html="true" data-bs-content="<div className='p-4 rounded bg-light'> <div className='d-flex flex-stack text-muted mb-4'> <i className='fas fa-university fs-3 me-3'></i> <div className='fwt-bold'>INCBANK **** 1245 STATEMENT</div> </div> <div className='d-flex flex-stack fwt-bold text-gray-600'> <div>Amount</div> <div>Transaction</div> </div> <div className='separator separator-dashed my-2'></div> <div className='d-flex flex-stack text-dark fwt-bolder mb-2'> <div>USD345.00</div> <div>KEENTHEMES*</div> </div> <div className='d-flex flex-stack text-muted mb-2'> <div>USD75.00</div> <div>Hosting fee</div> </div> <div className='d-flex flex-stack text-muted'> <div>USD3,950.00</div> <div>Payrol</div> </div> </div>" data-bs-original-title="" title=""></i>
                            </label>
                            {/*end::Label*/}
                            {/*begin::Input*/}
                            <input className="form-control form-control-lg form-control-solid" name="warehouse_capacity" onChange={handleChange} value={warehouseDetails.warehouse_capacity}/>
                        <div className="fv-plugins-message-container invalid-feedback"></div></div>
                        {/*end::Input group*/}
                        {/*begin::Input group*/}
                        {/*end::Input group*/}
                        {/*begin::Input group*/}
                        <div className="fv-row mb-10 fv-plugins-icon-container">
                            {/*end::Label*/}
                            <label className="form-label">Warehouse Description</label>
                            {/*end::Label*/}
                            {/*begin::Input*/}
                            <textarea name="warehouse_description" className="form-control form-control-lg form-control-solid" rows="3" onChange={handleChange} value={warehouseDetails.warehouse_description} ></textarea>
                            {/*end::Input*/}
                        <div className="fv-plugins-message-container invalid-feedback"></div></div>
                        {/*end::Input group*/}
                        {/*begin::Input group*/}
                    </div>
                    {/*end::Wrapper*/}
                </div>
                {/*end::Step 3*/}
                {/*begin::Actions*/}
                <div className="d-flex flex-stack pt-10">
                    {/*begin::Wrapper*/}
                    <div className="mr-2">
                    {currentStep > 0 &&
                        <button onClick={() => setCurrentStep(currentStep - 1)} type="button" className="btn btn-sm btn-light-primary me-3">
                        <span className="svg-icon svg-icon-4 me-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="6" y="11" width="13" height="2" rx="1" fill="black"></rect>
                                <path d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z" fill="black"></path>
                            </svg>
                        </span>
                        Back</button>}
                    </div>
                    <div>
                        {currentStep === maxStep &&
                        <button type="submit" className="btn btn-sm btn-beysix me-3" disabled={loading ? true : false}>
                            {!loading ? <span className="indicator-label">Submit
                            <span className="svg-icon svg-icon-3 ms-2 me-0 svg-icon-white">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="black"></rect>
                                    <path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="black"></path>
                                </svg>
                            </span>
                            </span> :
                            <span className="indicator-progress">Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>}
                        </button>}
                        {currentStep !== maxStep &&
                        <button type="button" className="btn btn-sm btn-beysix" onClick={handleContinue}>Continue
                        <span className="svg-icon svg-icon-4 ms-1 me-0">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="black"></rect>
                                <path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="black"></path>
                            </svg>
                        </span>
                        </button>}
                    </div>
                </div>
            <div></div><div></div><div></div><div></div></form>
            {/*end::Form*/}
        </div>
        {/*end::Content*/}
    </div>
}</>)
}
