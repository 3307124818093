import React, {useEffect, useState} from 'react'
import { useSelector } from 'react-redux'
import api from '../../../../store/api';
import MainBarChart from '../../../Charts/BarChart/BarChart';
import DoughnutChart from '../../../Charts/DoughnutChart/DoughnutChart';
import formatMoney from '../../../Utilities/FormatMoney';
import DashboardCardComponent from '../../DashboardCardComponent/DashboardCardComponent';

export default function AssetDashboard() {

  const [pageLoading, setPageLoading] = useState(true)
  const [assetCounts, setAssetCounts] = useState({})
  const [clientProjects, setClientProjects] = useState([])
  const [clientBrands, setClientBrands] = useState([])
  const [top10Projects_spaceOccupied, setTop10Projects_spaceOccupied] = useState([])
  const [top10Projects_assetCount, setTop10Projects_assetCount] = useState([])
  const [assetDataPerAgency, setAssetDataPerAgency] = useState([])
  const [projectPerAgency, setProjectPerAgency] = useState([])
  const [brandPerAgency, setBrandPerAgency] = useState([])
  const [projectItemCount, setProjectItemCount] = useState([])
  const [costPerAgency, setCostPerAgency] = useState([])
  const [costPerBrand, setCostPerBrand] = useState([])
  const [assetCustodyPerAgency, setAssetCustodyPerAgency] = useState([])
  const [assetCustodies, setAssetCustodies] = useState([])
  const [costPerProject, setCostPerProject] = useState([])


  const getAssetCountDetails = () => {
    api.get(`/api/dashboards/get-asset-count/${organization.id}/`,
      {headers: {'Content-Type': 'application/json', Authorization: `Token ${token}`}})
      .then((response) => {
          if (response.status === 200) {
            setAssetCounts(response.data.count)
            setClientProjects(response.data.project_table)
            setClientBrands(response.data.brands_data)
            setTop10Projects_spaceOccupied(response.data.top_projects_data_by_space_occupied)
            setTop10Projects_assetCount(response.data.top_projects_data)
            setAssetDataPerAgency(response.data.assets_per_commissioning_agency)
            setProjectPerAgency(response.data.project_per_agency)
            setBrandPerAgency(response.data.brands_per_commissioning_agency_count)
            setProjectItemCount(response.data.top_projects_data_per_item)
            setAssetCustodies(response.data.asset_custodies)
            setCostPerAgency(response.data.assets_cost_per_agency)
            setAssetCustodyPerAgency(response.data.agency_asset_custody)
            setCostPerBrand(response.data.assets_cost_per_brand)
            setCostPerProject(response.data.project_asset_cost_data)
            setPageLoading(false)
          }
      })
      .catch((error) => {
          // setPageLoading(false)
          throw new Error('Error getting data.')
      })
  }

  const {organization, token} = useSelector((state) => {
    return {
      'organization': state.loginSlice.organization, 
      'token': state.loginSlice.token
    }
  }); //

  useEffect(() => {
    getAssetCountDetails()
  }, [])

  return (
        <div className="row g-6 g-xl-9">
          <div className="col-lg-6 col-xxl-2" style={{minHeight: '200px'}}>
            {/*begin::Card*/}
            <DashboardCardComponent isLoading={pageLoading} >
              <div className="card-body p-6">
                      {/*begin::Heading*/}
                      <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <div className="fs-4 fw-bold">{assetCounts.total_assets__count}</div>
                        <span className="svg-icon svg-icon-muted svg-icon-2hx" style={{
                            display: 'flex',
                            alignItems: 'center'
                        }} ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path opacity="0.3" d="M22 19V17C22 16.4 21.6 16 21 16H8V3C8 2.4 7.6 2 7 2H5C4.4 2 4 2.4 4 3V19C4 19.6 4.4 20 5 20H21C21.6 20 22 19.6 22 19Z" fill="black"/>
                        <path d="M20 5V21C20 21.6 19.6 22 19 22H17C16.4 22 16 21.6 16 21V8H8V4H19C19.6 4 20 4.4 20 5ZM3 8H4V4H3C2.4 4 2 4.4 2 5V7C2 7.6 2.4 8 3 8Z" fill="black"/>
                        </svg></span>
                      </div>
                      <div className="ffwt-bold text-gray-400 mb-7" style={{fontSize: '15px'}}>Total {assetCounts.total_assets__count === 1 ? 'Asset' : 'Assets'}</div>
                  </div>
            </DashboardCardComponent>
            {/*end::Card*/}
        </div>
        <div className="col-lg-6 col-xxl-2" style={{minHeight: '200px'}}>
            {/*begin::Budget*/}
            <DashboardCardComponent isLoading={pageLoading} >
                {/*begin::Card body*/}
                <div className="card-body p-6">
                    {/*begin::Heading*/}
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div className="fs-4 fw-bold">{assetCounts.active_assets_count}</div>
                      <span className="svg-icon svg-icon-2hx svg-icon-success" style={{
                          display: 'flex',
                          alignItems: 'center'
                      }} ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path opacity="0.3" d="M22 19V17C22 16.4 21.6 16 21 16H8V3C8 2.4 7.6 2 7 2H5C4.4 2 4 2.4 4 3V19C4 19.6 4.4 20 5 20H21C21.6 20 22 19.6 22 19Z" fill="black"/>
                      <path d="M20 5V21C20 21.6 19.6 22 19 22H17C16.4 22 16 21.6 16 21V8H8V4H19C19.6 4 20 4.4 20 5ZM3 8H4V4H3C2.4 4 2 4.4 2 5V7C2 7.6 2.4 8 3 8Z" fill="black"/>
                      </svg></span>
                    </div>
                    <div className="ffwt-bold text-gray-400 mb-7" style={{fontSize: '15px'}}>Active {assetCounts.active_assets_count === 1 ? 'Asset' : 'Assets'}</div>
                </div>
              </DashboardCardComponent>
        </div>
        <div className="col-lg-6 col-xxl-2" style={{minHeight: '200px'}}>
            {/*begin::Clients*/}
            <DashboardCardComponent isLoading={pageLoading} >
                <div className="card-body p-6">
                    {/*begin::Heading*/}
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div className="fs-4 fw-bold">{assetCounts.inactive_assets_count}</div>
                      <span className="svg-icon svg-icon-2hx svg-icon-danger" style={{
                          display: 'flex',
                          alignItems: 'center'
                      }} ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path opacity="0.3" d="M22 19V17C22 16.4 21.6 16 21 16H8V3C8 2.4 7.6 2 7 2H5C4.4 2 4 2.4 4 3V19C4 19.6 4.4 20 5 20H21C21.6 20 22 19.6 22 19Z" fill="black"/>
                      <path d="M20 5V21C20 21.6 19.6 22 19 22H17C16.4 22 16 21.6 16 21V8H8V4H19C19.6 4 20 4.4 20 5ZM3 8H4V4H3C2.4 4 2 4.4 2 5V7C2 7.6 2.4 8 3 8Z" fill="black"/>
                      </svg></span>
                    </div>
                    <div className="ffwt-bold text-gray-400 mb-7" style={{fontSize: '15px'}}>Inactive {assetCounts.inactive_assets_count === 1 ? 'Asset' : 'Assets'}</div>
                </div>
                </DashboardCardComponent >
            {/*end::Clients*/}
        </div>
        <div className="col-lg-6 col-xxl-2" style={{minHeight: '200px'}}>
            {/*begin::Clients*/}
            <DashboardCardComponent isLoading={pageLoading} >
                <div className="card-body p-6">
                    {/*begin::Heading*/}
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    {/* {assetCounts.total_space_occupied} */}
                    <div className="fs-4 fw-bold">30,000 ft²</div> 
                     <span className="svg-icon svg-icon-muted svg-icon-2hx icon icon-success" style={{
                          display: 'flex',
                          alignItems: 'center'
                      }}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M8.7 4.19995L4 6.30005V18.8999L8.7 16.8V19L3.1 21.5C2.6 21.7 2 21.4 2 20.8V6C2 5.4 2.3 4.89995 2.9 4.69995L8.7 2.09998V4.19995Z" fill="black"/>
                      <path d="M15.3 19.8L20 17.6999V5.09992L15.3 7.19989V4.99994L20.9 2.49994C21.4 2.29994 22 2.59989 22 3.19989V17.9999C22 18.5999 21.7 19.1 21.1 19.3L15.3 21.8998V19.8Z" fill="black"/>
                      <path opacity="0.3" d="M15.3 7.19995L20 5.09998V17.7L15.3 19.8V7.19995Z" fill="black"/>
                      <path opacity="0.3" d="M8.70001 4.19995V2L15.4 5V7.19995L8.70001 4.19995ZM8.70001 16.8V19L15.4 22V19.8L8.70001 16.8Z" fill="black"/>
                      <path opacity="0.3" d="M8.7 16.8L4 18.8999V6.30005L8.7 4.19995V16.8Z" fill="black"/>
                      </svg></span>
                    </div>
                    <div className="ffwt-bold text-gray-400 mb-7" style={{fontSize: '15px'}}>Space Occupied</div>
                </div>
                </DashboardCardComponent >
            {/*end::Clients*/}
        </div>
        <div className="col-lg-6 col-xxl-2" style={{minHeight: '200px'}}>
            {/*begin::Clients*/}
            <DashboardCardComponent isLoading={pageLoading} >
                <div className="card-body p-6">
                    {/*begin::Heading*/}
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div className="fs-4 fw-bold">KES {formatMoney(assetCounts.total_cost)}</div>
                     <span className="svg-icon svg-icon-muted svg-icon-2hx icon icon-success" style={{
                          display: 'flex',
                          alignItems: 'center'
                      }}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path opacity="0.3" d="M12.5 22C11.9 22 11.5 21.6 11.5 21V3C11.5 2.4 11.9 2 12.5 2C13.1 2 13.5 2.4 13.5 3V21C13.5 21.6 13.1 22 12.5 22Z" fill="black"/>
                      <path d="M17.8 14.7C17.8 15.5 17.6 16.3 17.2 16.9C16.8 17.6 16.2 18.1 15.3 18.4C14.5 18.8 13.5 19 12.4 19C11.1 19 10 18.7 9.10001 18.2C8.50001 17.8 8.00001 17.4 7.60001 16.7C7.20001 16.1 7 15.5 7 14.9C7 14.6 7.09999 14.3 7.29999 14C7.49999 13.8 7.80001 13.6 8.20001 13.6C8.50001 13.6 8.69999 13.7 8.89999 13.9C9.09999 14.1 9.29999 14.4 9.39999 14.7C9.59999 15.1 9.8 15.5 10 15.8C10.2 16.1 10.5 16.3 10.8 16.5C11.2 16.7 11.6 16.8 12.2 16.8C13 16.8 13.7 16.6 14.2 16.2C14.7 15.8 15 15.3 15 14.8C15 14.4 14.9 14 14.6 13.7C14.3 13.4 14 13.2 13.5 13.1C13.1 13 12.5 12.8 11.8 12.6C10.8 12.4 9.99999 12.1 9.39999 11.8C8.69999 11.5 8.19999 11.1 7.79999 10.6C7.39999 10.1 7.20001 9.39998 7.20001 8.59998C7.20001 7.89998 7.39999 7.19998 7.79999 6.59998C8.19999 5.99998 8.80001 5.60005 9.60001 5.30005C10.4 5.00005 11.3 4.80005 12.3 4.80005C13.1 4.80005 13.8 4.89998 14.5 5.09998C15.1 5.29998 15.6 5.60002 16 5.90002C16.4 6.20002 16.7 6.6 16.9 7C17.1 7.4 17.2 7.69998 17.2 8.09998C17.2 8.39998 17.1 8.7 16.9 9C16.7 9.3 16.4 9.40002 16 9.40002C15.7 9.40002 15.4 9.29995 15.3 9.19995C15.2 9.09995 15 8.80002 14.8 8.40002C14.6 7.90002 14.3 7.49995 13.9 7.19995C13.5 6.89995 13 6.80005 12.2 6.80005C11.5 6.80005 10.9 7.00005 10.5 7.30005C10.1 7.60005 9.79999 8.00002 9.79999 8.40002C9.79999 8.70002 9.9 8.89998 10 9.09998C10.1 9.29998 10.4 9.49998 10.6 9.59998C10.8 9.69998 11.1 9.90002 11.4 9.90002C11.7 10 12.1 10.1 12.7 10.3C13.5 10.5 14.2 10.7 14.8 10.9C15.4 11.1 15.9 11.4 16.4 11.7C16.8 12 17.2 12.4 17.4 12.9C17.6 13.4 17.8 14 17.8 14.7Z" fill="black"/>
                      </svg></span>
                    </div>
                    <div className="ffwt-bold text-gray-400 mb-7" style={{fontSize: '15px'}}>Total Asset Cost</div>
                </div>
                </DashboardCardComponent>
            {/*end::Clients*/}
        </div>
        <div className="col-lg-6 col-xxl-2" style={{minHeight: '200px'}}>
            {/*begin::Clients*/}
            <DashboardCardComponent isLoading={pageLoading} >
                <div className="card-body p-6">
                    {/*begin::Heading*/}
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    {/* {formatMoney(assetCounts.space_occupied_cost)} */}
                    <div className="fs-4 fw-bold">KES 0.00</div>
                     <span className="svg-icon svg-icon-muted svg-icon-2hx icon icon-success" style={{
                          display: 'flex',
                          alignItems: 'center'
                      }}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path opacity="0.3" d="M12.5 22C11.9 22 11.5 21.6 11.5 21V3C11.5 2.4 11.9 2 12.5 2C13.1 2 13.5 2.4 13.5 3V21C13.5 21.6 13.1 22 12.5 22Z" fill="black"/>
                      <path d="M17.8 14.7C17.8 15.5 17.6 16.3 17.2 16.9C16.8 17.6 16.2 18.1 15.3 18.4C14.5 18.8 13.5 19 12.4 19C11.1 19 10 18.7 9.10001 18.2C8.50001 17.8 8.00001 17.4 7.60001 16.7C7.20001 16.1 7 15.5 7 14.9C7 14.6 7.09999 14.3 7.29999 14C7.49999 13.8 7.80001 13.6 8.20001 13.6C8.50001 13.6 8.69999 13.7 8.89999 13.9C9.09999 14.1 9.29999 14.4 9.39999 14.7C9.59999 15.1 9.8 15.5 10 15.8C10.2 16.1 10.5 16.3 10.8 16.5C11.2 16.7 11.6 16.8 12.2 16.8C13 16.8 13.7 16.6 14.2 16.2C14.7 15.8 15 15.3 15 14.8C15 14.4 14.9 14 14.6 13.7C14.3 13.4 14 13.2 13.5 13.1C13.1 13 12.5 12.8 11.8 12.6C10.8 12.4 9.99999 12.1 9.39999 11.8C8.69999 11.5 8.19999 11.1 7.79999 10.6C7.39999 10.1 7.20001 9.39998 7.20001 8.59998C7.20001 7.89998 7.39999 7.19998 7.79999 6.59998C8.19999 5.99998 8.80001 5.60005 9.60001 5.30005C10.4 5.00005 11.3 4.80005 12.3 4.80005C13.1 4.80005 13.8 4.89998 14.5 5.09998C15.1 5.29998 15.6 5.60002 16 5.90002C16.4 6.20002 16.7 6.6 16.9 7C17.1 7.4 17.2 7.69998 17.2 8.09998C17.2 8.39998 17.1 8.7 16.9 9C16.7 9.3 16.4 9.40002 16 9.40002C15.7 9.40002 15.4 9.29995 15.3 9.19995C15.2 9.09995 15 8.80002 14.8 8.40002C14.6 7.90002 14.3 7.49995 13.9 7.19995C13.5 6.89995 13 6.80005 12.2 6.80005C11.5 6.80005 10.9 7.00005 10.5 7.30005C10.1 7.60005 9.79999 8.00002 9.79999 8.40002C9.79999 8.70002 9.9 8.89998 10 9.09998C10.1 9.29998 10.4 9.49998 10.6 9.59998C10.8 9.69998 11.1 9.90002 11.4 9.90002C11.7 10 12.1 10.1 12.7 10.3C13.5 10.5 14.2 10.7 14.8 10.9C15.4 11.1 15.9 11.4 16.4 11.7C16.8 12 17.2 12.4 17.4 12.9C17.6 13.4 17.8 14 17.8 14.7Z" fill="black"/>
                      </svg></span>
                    </div>
                    <div className="ffwt-bold text-gray-400 mb-7" style={{fontSize: '15px'}}>Space Occupied Cost</div>
                </div>
            </DashboardCardComponent>
            {/*end::Clients*/}
        </div>
        {/* Agency Data */}
        <div className="col-lg-6 col-xxl-8 mt-3" style={{minHeight: '350px'}}>
            <DashboardCardComponent isLoading={pageLoading} >
                {/*begin::Header*/}
                <div className="card-header border-0 pt-2">
                  <h5 className="card-title align-items-start flex-column mb-2">
                    <span className="fwt-bolder text-dark">Assets Per Commissioning Agency</span>
                  </h5>
                <MainBarChart _data = {assetDataPerAgency} />
            </div>
            </DashboardCardComponent>
            </div>
            <div className="col-xl-4 mt-3">
            {/*begin::List Widget 4*/}
            <DashboardCardComponent isLoading={pageLoading} >
              {/*begin::Header*/}
              <div className="card-header border-0 pt-2">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fwt-bolder text-dark">Asset Custodies</span>
                </h3>
              </div>
              {/*end::Header*/}
              {/*begin::Body*/}
              <div className="card-body pt-5" style={{maxHeight: '370px', minHeight: '370px', overflow: 'auto'}}>
                {/*begin::Item*/}
                {assetCustodyPerAgency.map((agency) =><div className="d-flex align-items-sm-center mb-7">
                  {/*begin::Symbol*/}
                  <div className="symbol symbol-50px me-5">
                    <span className="symbol-label">
                      {agency.profile_photo_path ? <img src={agency.profile_photo_path} className="align-self-center" alt={agency.name} style={{objectFit: 'cover', maxWidth: '100%', minWidth: '100%'}}/>:
                        <span className="svg-icon svg-icon-muted svg-icon-3x"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path opacity="0.3" d="M22 19V17C22 16.4 21.6 16 21 16H8V3C8 2.4 7.6 2 7 2H5C4.4 2 4 2.4 4 3V19C4 19.6 4.4 20 5 20H21C21.6 20 22 19.6 22 19Z" fill="black"></path>
                        <path d="M20 5V21C20 21.6 19.6 22 19 22H17C16.4 22 16 21.6 16 21V8H8V4H19C19.6 4 20 4.4 20 5ZM3 8H4V4H3C2.4 4 2 4.4 2 5V7C2 7.6 2.4 8 3 8Z" fill="black"></path></svg></span> 
                      }
                    </span>
                  </div>
                  {/*end::Symbol*/}
                  {/*begin::Section*/}
                  <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                    <div className="flex-grow-1 me-2">
                      <a  className="text-gray-800 text-hover-primary fs-6 fw-bold">{agency.name}</a>
                    </div>
                    <span className="badge badge-light fw-bold my-2">{agency.value} {agency.value === 1 ? 'Asset' : 'Assets'}</span>
                  </div>
                  {/*end::Section*/}
                </div>)}
              </div>
              {/*end::Body*/}
            </DashboardCardComponent>
            {/*end::List Widget 4*/}
          </div>
          <div className="col-lg-6 col-xxl-12 mt-3" style={{minHeight: '350px'}}>
            <DashboardCardComponent isLoading={pageLoading} >
                {/*begin::Header*/}
                <div className="card-header border-0 pt-2">
                  <h5 className="card-title align-items-start flex-column mb-2">
                    <span className="fwt-bolder text-dark">Asset custody per agency</span>
                  </h5>
                <MainBarChart _data = {assetCustodyPerAgency} />
            </div>
            </DashboardCardComponent>
            </div>
          <div className="col-lg-6 col-xxl-12 mt-3" style={{minHeight: '350px'}}>
            <DashboardCardComponent isLoading={pageLoading} >
                {/*begin::Header*/}
                <div className="card-header border-0 pt-2">
                  <h5 className="card-title align-items-start flex-column mb-2">
                    <span className="fwt-bolder text-dark">Asset Cost Per Commissioning Agency</span>
                  </h5>
                <MainBarChart _data = {costPerAgency} _money={true}/>
            </div>
            </DashboardCardComponent>
            </div>


            {/* Brand Data */}
            <div className="col-xl-4 mt-3">
										{/*begin::List Widget 4*/}
										<DashboardCardComponent isLoading={pageLoading} >
											{/*begin::Header*/}
											<div className="card-header border-0 pt-2">
												<h3 className="card-title align-items-start flex-column">
													<span className="card-label fwt-bolder text-dark">Brands</span>
													<span className="text-muted mt-1 fwt-bold fs-7">Total of {clientBrands.length} brands</span>
												</h3>
											</div>
											{/*end::Header*/}
											{/*begin::Body*/}
											<div className="card-body pt-5" style={{maxHeight: '370px', overflow: 'auto'}}>
												{/*begin::Item*/}
												{clientBrands.map((brand) =><div className="d-flex align-items-sm-center mb-7">
													{/*begin::Symbol*/}
													<div className="symbol symbol-50px me-5">
														<span className="symbol-label">
															<img src={brand.image_path} className="align-self-center" alt={brand.name} 
                              style={{objectFit: 'cover', maxWidth: '100%', minWidth: '100%'}}/>
														</span>
													</div>
													{/*end::Symbol*/}
													{/*begin::Section*/}
													<div className="d-flex align-items-center flex-row-fluid flex-wrap">
														<div className="flex-grow-1 me-2">
															<a  className="text-gray-800 text-hover-primary fs-6 fw-bold">{brand.name}</a>
															<span className="text-muted fw-bold d-block fs-7">{`${brand.assets_count} ${brand.assets_count === 1 ? 'Asset' : 'Assets'}`}</span>
														</div>
													</div>
													{/*end::Section*/}
												</div>)}
											</div>
											{/*end::Body*/}
										</DashboardCardComponent>
										{/*end::List Widget 4*/}
									</div>
                      <div className="col-lg-6 col-xxl-8 mt-3" style={{minHeight: '350px'}}>
                    <DashboardCardComponent isLoading={pageLoading} >
                        {/*begin::Header*/}
                        <div className="card-header border-0 pt-2">
                          <h5 className="card-title align-items-start flex-column mb-2">
                            <span className="fwt-bolder text-dark">Brands Per Commissioning Agency</span>
                          </h5>
                        <MainBarChart _data = {brandPerAgency} />
                    </div>
                    </DashboardCardComponent>
                    </div>
                    <div className="col-lg-6 col-xxl-12 mt-3" style={{minHeight: '350px'}}>
                    <DashboardCardComponent isLoading={pageLoading} >
                        {/*begin::Header*/}
                        <div className="card-header border-0 pt-2">
                          <h5 className="card-title align-items-start flex-column mb-2">
                            <span className="fwt-bolder text-dark">Assets Per Brand</span>
                          </h5>
                        <MainBarChart _data = {clientBrands} _value={'assets_count'} />
                    </div>
                    </DashboardCardComponent>
                    </div>
                  <div className="col-lg-6 col-xxl-12 mt-3" style={{minHeight: '350px'}}>
                    <DashboardCardComponent isLoading={pageLoading} >
                        {/*begin::Header*/}
                        <div className="card-header border-0 pt-2">
                          <h5 className="card-title align-items-start flex-column mb-2">
                            <span className="fwt-bolder text-dark">Asset Cost Per Brand</span>
                          </h5>
                        <MainBarChart _data = {costPerBrand} _money={true} />
                    </div>
                    </DashboardCardComponent>
                    </div>

        <div className="col-xl-4 mt-3">
										{/*begin::List Widget 4*/}
										<DashboardCardComponent isLoading={pageLoading} >
											{/*begin::Header*/}
											<div className="card-header border-0 pt-2">
												<h3 className="card-title align-items-start flex-column">
													<span className="card-label fwt-bolder text-dark">Projects</span>
													<span className="text-muted mt-1 fwt-bold fs-7">Total of {clientProjects.length} projects</span>
												</h3>
											</div>
											{/*end::Header*/}
											{/*begin::Body*/}
											<div className="card-body pt-5" style={{maxHeight: '370px', overflow: 'auto'}}>
												{/*begin::Item*/}
												{clientProjects.map((project) =><div className="d-flex align-items-sm-center mb-7">
													{/*begin::Symbol*/}
													<div className="symbol symbol-50px me-5">
														<span className="symbol-label">
															<img src={project.image_path} className="align-self-center" alt={project.name} 
                              style={{objectFit: 'cover', maxWidth: '100%', minWidth: '100%'}}/>
														</span>
													</div>
													{/*end::Symbol*/}
													{/*begin::Section*/}
													<div className="d-flex align-items-center flex-row-fluid flex-wrap">
														<div className="flex-grow-1 me-2">
															<a  className="text-gray-800 text-hover-primary fs-6 fw-bold">{project.name}</a>
															<span className="text-muted fw-bold d-block fs-7">{`${project.assets_count} ${project.assets_count === 1 ? 'Asset' : 'Assets'}`}</span>
														</div>
														<span className="badge badge-light fw-bold my-2">{project.brand_name}</span>
													</div>
													{/*end::Section*/}
												</div>)}
											</div>
											{/*end::Body*/}
										</ DashboardCardComponent>
										{/*end::List Widget 4*/}
									</div>
                  <div className="col-lg-6 col-xxl-8 mt-3" style={{minHeight: '350px'}}>
                    <DashboardCardComponent isLoading={pageLoading} >
                        {/*begin::Header*/}
                        <div className="card-header border-0 pt-2">
                          <h5 className="card-title align-items-start flex-column mb-2">
                            <span className="fwt-bolder text-dark">Projects Per Commissioning Agency</span>
                          </h5>
                      <MainBarChart _data = {projectPerAgency} />
                    </div>
                    </DashboardCardComponent>
                    </div>
                    <div className="col-lg-4 col-xxl-4 mt-3" style={{minHeight: '350px'}}>
                    <DashboardCardComponent isLoading={pageLoading} >
                        {/*begin::Header*/}
                        <div className="card-header border-0 pt-2">
                          <h5 className="card-title align-items-start flex-column mb-2">
                            <span className="fwt-bolder text-dark">Top 10 Projects In Regards To Number Of Assets</span>
                          </h5>
                      <DoughnutChart _data = {top10Projects_assetCount} />
                    </div>
                    </DashboardCardComponent>
                    </div>
                    <div className="col-lg-4 col-xxl-4 mt-3">
                    <DashboardCardComponent isLoading={pageLoading} >
                        {/*begin::Header*/}
                        <div className="card-header border-0 pt-2">
                          <h5 className="card-title align-items-start flex-column mb-2">
                            <span className="fwt-bolder text-dark">Top 10 Projects In Regards To Number Of Items</span>
                          </h5>
                      <DoughnutChart _data = {projectItemCount} />
                    </div>
                    </DashboardCardComponent>          
                    </div>          
                    <div className="col-lg-4 col-xxl-4 mt-3">
                    <DashboardCardComponent isLoading={pageLoading} >
                        {/*begin::Header*/}
                        <div className="card-header border-0 pt-2">
                          <h5 className="card-title align-items-start flex-column mb-2">
                            <span className="fwt-bolder text-dark">Top 10 Projects In Regards To Space Occupied</span>
                          </h5>
                      <DoughnutChart _data = {top10Projects_spaceOccupied} />
                    </div>
                    </DashboardCardComponent>          
                    </div>   
                    
                      <div className="col-lg-6 col-xxl-12 mt-3" style={{minHeight: '350px'}}>
                    <DashboardCardComponent isLoading={pageLoading} >
                        {/*begin::Header*/}
                        <div className="card-header border-0 pt-2">
                          <h5 className="card-title align-items-start flex-column mb-2">
                            <span className="fwt-bolder text-dark">Assets Per Project</span>
                          </h5>
                        <MainBarChart _data = {clientProjects} _value={'assets_count'} />
                    </div>
                    </DashboardCardComponent>
                    </div>
                  <div className="col-lg-6 col-xxl-12 mt-3" style={{minHeight: '350px'}}>
                    <DashboardCardComponent isLoading={pageLoading} >
                        {/*begin::Header*/}
                        <div className="card-header border-0 pt-2">
                          <h5 className="card-title align-items-start flex-column mb-2">
                            <span className="fwt-bolder text-dark">Asset Cost Per Project</span>
                          </h5>
                        <MainBarChart _data = {costPerProject}  _money={true} />
                    </div>
                    </DashboardCardComponent>
                    </div>
									</div>    

  )
}
