import React from "react";
import 'react-loading-skeleton/dist/skeleton.css';
import "../../../Brand/SkeletonLoader/SkeletonLoader.css";

const CalendarSkeleton = () => {
  const renderDays = () => {
    const days = [];
    for (let i = 0; i < 21; i++) {
      days.push(
        <div key={i} className="skeleton-day"></div>
      );
    }
    return days;
  };

  return (
    <div className="skeleton-calendar">
      <div className="skeleton-header">
        <div className="skeleton-title"></div>
        <div className="skeleton-controls">
          <div className="skeleton-header">
            <div className="skeleton-title"></div>
          </div>
        </div>
      </div>
      <div className="skeleton-weekdays">
        {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day, index) => (
          <div key={index} className="skeleton-weekday"></div>
        ))}
      </div>
      <div className="skeleton-days">{renderDays()}</div>
    </div>
  );
};

export default CalendarSkeleton;
