import React, {useState, useEffect} from 'react'
import api from '../../../store/api'
import { useSelector } from 'react-redux'
import parseDateTimestamp from '../../Utilities/parseDateTimeStamp.'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import AccessoryTable from '../../Accessories/AccessoryTable'

export default function ProjectAccessories() {

  const [accessories, setAccessories] = useState([])
  const [pageLoading, setPageLoading] = useState(false)
  const [resultsFound, setResultsFound] = useState(true)
    const token = useSelector((state) => state.loginSlice.token);
    const { id } = useParams()

    const getAccessories = () => {
        setPageLoading(true)
        api.get(`/api/projects/project-accessories/${id}/`,
        {headers: {'Content-Type': 'application/json', Authorization: `Token ${token}`}})
        .then((response) => {
            if (response.data.results.length === 0) {
                setResultsFound(false)
            } else {
                setAccessories(response.data.results)
            }
            setPageLoading(false)
        })
        .catch((error) => {
            throw new Error('Error getting data.')
        })
    }

    useEffect(() => {
      getAccessories()
    }, [])

  return (
    <div className="card mb-5 mb-xl-8">
    <div className="card-header border-0 pt-5">
    </div>
    {/*end::Header*/}
    {/*begin::Body*/}
    <div className="card-body py-3">
    {/*begin::Table container*/}
    <AccessoryTable accessories={accessories}  resultsFound={resultsFound} loading={pageLoading} editable={false} />
    {/*end::Table container*/}
    </div>
    {/*begin::Body*/}
    </div>
  )
}
