import React, {useEffect} from 'react'
import SkeletonLoader from './SkeletonLoader/SkeletonLoader';
import NoResults from '../Utilities/NoResults';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import parseDateTimestamp from '../Utilities/parseDateTimeStamp.';
import { Link } from 'react-router-dom';

export default function ToolTable({tools, loading, resultsFound, setCurrentPk, setEditToolModal, setToolDetailModal, getToolSerials}) {

    const permissions = useSelector((state) => state.loginSlice.permissions);

  return (
    <>
    {!loading ?
        resultsFound ?
    <div className="table-responsive">
        {/*begin::Table*/}
        <motion.table
        initial={{ y: 100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.3 }}
        className="table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 p-5"
        >
            {/*begin::Table head*/}
            <thead>
                <tr className="fw-bolder text-muted bg-light">
                    <th className="ps-4 min-w-200px rounded-start">Tool Name</th>
                    <th className="min-w-150px">Created At</th>
                    <th className="min-w-150px">Created By</th>
                    <th className="min-w-150px">Total Quantity</th>
                    <th className="min-w-150px">Inside Warehouse</th>
                    <th className="min-w-150px">Outside Warehouse</th>
                    {permissions['change_tool'] && <th className="min-w-100px text-end rounded-end"></th>}
                </tr>
            </thead>
            {/*end::Table head*/}
            {/*begin::Table body*/}
            <tbody>
                {tools.map((tool) => {
                return (<tr>
                    <td>
                        <div className="d-flex align-items-center">
                            <div className="symbol symbol-50px me-5">
                                <span className="symbol-label bg-light">
                                <span className="svg-icon svg-icon-muted svg-icon-3x">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path opacity="0.3" d="M22.0318 8.59998C22.0318 10.4 21.4318 12.2 20.0318 13.5C18.4318 15.1 16.3318 15.7 14.2318 15.4C13.3318 15.3 12.3318 15.6 11.7318 16.3L6.93177 21.1C5.73177 22.3 3.83179 22.2 2.73179 21C1.63179 19.8 1.83177 18 2.93177 16.9L7.53178 12.3C8.23178 11.6 8.53177 10.7 8.43177 9.80005C8.13177 7.80005 8.73176 5.6 10.3318 4C11.7318 2.6 13.5318 2 15.2318 2C16.1318 2 16.6318 3.20005 15.9318 3.80005L13.0318 6.70007C12.5318 7.20007 12.4318 7.9 12.7318 8.5C13.3318 9.7 14.2318 10.6001 15.4318 11.2001C16.0318 11.5001 16.7318 11.3 17.2318 10.9L20.1318 8C20.8318 7.2 22.0318 7.59998 22.0318 8.59998Z" fill="black"/>
                                    <path d="M4.23179 19.7C3.83179 19.3 3.83179 18.7 4.23179 18.3L9.73179 12.8C10.1318 12.4 10.7318 12.4 11.1318 12.8C11.5318 13.2 11.5318 13.8 11.1318 14.2L5.63179 19.7C5.23179 20.1 4.53179 20.1 4.23179 19.7Z" fill="black"/>
                                    </svg></span>
                                </span>
                            </div>
                            <div className="d-flex justify-content-start flex-column">
                                <a onClick={() => {
                                    setCurrentPk(tool.id)
                                    getToolSerials(tool.id)
                                    setToolDetailModal(true)}
                                } className="text-dark fw-bolder mb-1 fs-6">{tool.tool_name}</a>
                            </div>
                        </div>
                    </td>
                    <td>
                        <a className="text-dark fw-bolder d-block mb-1 fs-6">{parseDateTimestamp(tool.created_at)}</a>
                    </td>
                    <td>
                        <Link to={`/user-management/users/user-profile/${tool.created_by}`} className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">{tool.created_by_name}</Link>
                    </td>
                    <td>
                        {
                            tool.assets !== '' ?
                                <a className="text-dark fw-bolder d-block mb-1 fs-6">{tool.quantity}</a> :
                            '-'
                        }
                    </td>
                    <td>
                        {
                            tool.assets !== '' ?
                                <a className="text-dark fw-bolder d-block mb-1 fs-6">{tool.inside_warehouse}</a> :
                            '-'
                        }
                    </td>
                    <td>
                        {
                            tool.assets !== '' ?
                                <a className="text-dark fw-bolder d-block mb-1 fs-6">{tool.outside_warehouse}</a> :
                            '-'
                        }
                    </td>
                    {permissions['change_tool'] && <td className="text-end">
                        <a
                            onClick={() => {
                            setCurrentPk(tool.id);
                            setEditToolModal(true);
                        }}
                        className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4"
                        >Edit</a>
                    </td>}
                </tr>)})}
            </tbody>
            {/*end::Table body*/}
        </motion.table>
        {/*end::Table*/}
    </div>:
            <NoResults /> :
        <SkeletonLoader />}
    </>
  )
}
