import * as Sentry from "@sentry/react";
import React, { useState, useEffect } from "react";
import { Provider } from "react-redux";
import { store, persistor } from "./store/index";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivateRoutes from "./routes/PrivateRoutes";
import PublicRoutes from "./routes/PublicRoutes";
import RedirectHandler from "./components/RedirectHandler/RedirectHandler";
import { PersistGate } from "redux-persist/integration/react";
import publicRoutes from "./routes/pubRoutes";
import privateRoutes from "./routes/priRoutes";
import SystemActiveStatus from "./components/SystemActiveStatus/SystemActiveStatus";
import api from "./store/api";

const App = () => {
  const [status, setStatus] = useState(true);

  useEffect(() => {
    const fetchSystemStatus = async () => {
      try {
        const response = await api.get(`/is_system_active/`, {
          headers: { "Content-Type": "application/json" },
        });

        if (response.status === 200) {
          const data = response.data;
          if (data) {
            setStatus(data.is_active); // Update the state here
          }
        }
      } catch (error) {
        console.error("Error getting data:", error);
        setStatus(false); // Fallback to false in case of error
      }
    };

    fetchSystemStatus();
  }, []);

  return status === false ? (
    <SystemActiveStatus />
  ) : (
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <RedirectHandler />
            <PublicRoutes routes={publicRoutes} />
            <PrivateRoutes routes={privateRoutes} />
          </Router>
        </PersistGate>
      </Provider>
    </Sentry.ErrorBoundary>
  );
};

export default App;
