import React, {useState, useEffect, useRef} from 'react'
import deleteLeafletAttribution from '../../Utilities/deleteLeafletAttribution';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import "leaflet/dist/leaflet.css";
import L from 'leaflet';
import 'leaflet-search/dist/leaflet-search.min.css';
import 'leaflet-search';
import ProgressChart from '../../Utilities/ProgressChart';
import { Link } from 'react-router-dom';
import getRandomHexColor from '../../Utilities/getRandomHexColor';
import SkeletonLoader from '../../Accessories/SkeletonLoader/SkeletonLoader';
import { motion } from 'framer-motion';


export default function ActivationOverview({activationData, loading}) {

    const mapRef = useRef(null)
    const marker = useRef(null)
    const initCoordinates = [-1.2460227009959508, 36.68168221244762];
    const [markerPosition, setMarkerPosition] = useState(null)
    const [crewData, setCrewData] = useState([])
    const onMapReady = (map) => {
        map = map.current
        if (map){
            map.invalidateSize()
        }
    };

    const handleSetMarkerPosition = (coordinates) => {
        const { lat, long } = coordinates
        if ((lat && long) && mapRef.current) {
            setMarkerPosition({lat: parseFloat(lat), lng: parseFloat(long)})
            const marker = L.marker([51.505, -0.09], { icon: customIcon }).addTo(mapRef.current);
            marker.setLatLng([lat, long]);
            const newLatLng = marker._latlng;
            mapRef.current.flyTo(newLatLng, 14);
        }
    }

    const customIcon = L.icon({
        iconUrl: '/static/assets/media/illustrations/beysix-1/location-pin.png',
        iconSize: [38, 38],
        iconAnchor: [19, 38],
        popupAnchor: [0, -38]
    });

    useEffect(() => {
        handleSetMarkerPosition({lat: activationData.activationLocationLatitude , long: activationData.activationLocationLongitude})
        onMapReady(mapRef)
        deleteLeafletAttribution()
        setCrewData(activationData?.activation_crew)
    }, [activationData])

  return (
    <div className='row'>
        <div className='col-md-6'>
        <div className="card card-flush">
                <div className="card-body d-flex flex-column" style={{padding: "0px"}}>
                    <MapContainer
                            ref={mapRef}
                            center={initCoordinates}
                            zoom={14}
                            style={{ height: "400px", width: "100%" }}
                        >
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        {markerPosition && (
                            <Marker position={markerPosition} icon={customIcon} ref={marker}>
                                <Popup>
                                    {activationData.activationName.concat(' Activation')}<br /> {markerPosition.lat.toFixed(4)}, {markerPosition.lng.toFixed(4)}
                                </Popup>
                            </Marker>
                        )}
                    </MapContainer>
                    </div>
                </div>
            </div>
    
    <div className="col-md-6">
    <div className="card card-xl-stretch mb-5 mb-xl-8">
        <div className="card-header border-0 pt-1">
            <h4 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold fs-6 mb-1">Crew Members</span>
            </h4>
        </div>
        
        {!loading ?
        <div className="card-body py-3">
            {crewData?.crew_members?.length > 0 ? 
            <div className="table-responsive" style={{maxHeight: '300px'}}> 
                <motion.table
                    initial={{ y: 100, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.3 }}
                    className="table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 p-5"
                >
                    <thead>
                        <tr className='text-center fs-6 ftw-bold' style={{color: 'black'}}>
                            <th className="p-0 w-50px bg-f-div-white"></th>
                            <th className="p-0 min-w-150px">Crew Member</th>
                            <th className="p-0 min-w-125px">Role(s)</th>
                            <th className="p-0 min-w-150px">Phone Number</th>
                        </tr>
                    </thead>
                    <tbody>
                        {crewData?.crew_members?.map((crew, index) => {
                            const { darkerShade, brighterShade } = getRandomHexColor()
                        return  (<tr key={index}>
                            <td>
                                <div className="symbol symbol-50px me-2">
                                    {(crew?.profile?.profile_photo_path !== null && crew?.profile?.profile_photo_path !== '') ?
                                    <span className="symbol-label">
                                        <img style={{minWidth: '100%', objectFit: 'cover', minHeight: '100%'}} src={crew?.profile?.profile_photo_path} className="h-50 align-self-center" alt={crew?.profile?.profile_photo_name} />
                                    </span> : 
                                    <span className="symbol-label fw-bolder" style={{background: brighterShade, color: darkerShade}}>{crew?.profile?.first_name[0]}{crew?.profile?.last_name[0]}</span>
                                    }
                                </div>
                            </td>
                            <td>
                                <Link to={`/user-management/users/user-profile/${crew?.profile?.user_id}`} className="text-dark fw-bolder text-hover-primary mb-1 fs-6">{crew?.profile?.first_name} {crew?.profile?.last_name}</Link>
                                <span className="text-muted fwt-bold d-block fs-7">{crew.email}</span>
                            </td>
                            <td className="text-end">
                            {crew?.profile?.user_id === crewData?.senior_team_leader?.id && <span className="mt-1 badge badge-light-success fw-bold me-1">Senior Team Leader</span>}
                            {crewData?.team_leaders?.some(
                                (team_leader) => team_leader.id === crew?.profile?.user_id
                                ) && (
                                <span className="mt-1 badge badge-light-warning fw-bold me-1">Team Leader</span>
                                )}
                                <span className="mt-1 badge badge-light fw-bold me-1">{crew?.profile?.crew_role}</span>
                            </td>
                            <td className="text-end">
                                <span className="text-muted fwt-bold fs-6">{crew?.profile?.phone_number}</span>
                            </td>
                        </tr>)})}
                    </tbody>
                </motion.table>
            </div> : 
            <div className='text-center'>
                <p className='fs-6 ftw-bold'>No crew Members Have Been Configured.</p>
            </div>
            }
            {/*end::Table container*/}
        </div> :
        <SkeletonLoader overflowHidden={true} maxHeight={'300px'}/>}
        {/*end::Body*/}
    </div>
    {/*end::Tables Widget 2*/}
</div>
</div>
  )
}
